import { atom } from "recoil";
import AvatarUser from "assets/img/avatar2.png";
import { localStoragePersistRecoil } from "utils/Local-services";

export const userInfo = atom({
    key: "userInfo",
    default: {
        usuarioNombres: "Backuser",
        usuarioApellidos: "",
        imagePathPERFIL: AvatarUser,
    },
});

export const userCredentials = atom({
    key: "userCredentials",
    default: {},
});

export const routeInfo = atom({
    key: "routeInfo",
    default: {
        route: "/home",
    },
});

export const modalData = atom({
    key: "modalIsOpen",
    default: {
        isOpen: false,
        confirmationModalOpen: false,
        info: null,
        action: null,
        title: "",
    },
});

export const secondaryModalData = atom({
    key: "secondaryModalIsOpen",
    default: {
        isOpen: false,
        confirmationModalOpen: false,
        info: null,
        action: null,
        title: "",
    },
});

export const availableMerchants = atom({
    key: "availableMerchants",
    default: [],
});

export const rowSelected = atom({
    key: "rowSelected",
    default: {
        status: false,
        row: {},
    },
});

export const gruposFacturacion = atom({
    key: "gruposFacturacion",
    default: [],
});

export const snackbarData = atom({
    key: "snackbarData",
    default: {
        open: false,
    },
});

export const breadcrumbState = atom({
    key: "breadcrumbState",
    default: [],
    effects: [localStoragePersistRecoil("breadcrumb_state")],
});

export const paginationState = atom({
    key: "paginationState",
    default: 0,
});
