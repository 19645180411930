import { makeStyles } from "@material-ui/core/styles";

export const XGridStyles = makeStyles(() => ({
    root: {
        cursor: "pointer",
        fontSize: "1.1rem",
        position: "relative",
        "& *": {
            outline: "none!important",
            overflow: "visible",
        },
        "& .MuiDataGrid-footerContainer": {
            justifyContent: "flex-start!important",
        },
        "& .MuiDataGrid-row": {
            "&:hover": {
                backgroundColor: "#F2F2F2",
            },
        },
        "& .MuiDataGrid-row.Mui-even": {
            backgroundColor: "#FAFAFA",
        },
        "& .MuiDataGrid-row.Mui-odd": {
            backgroundColor: "#FFF",
        },
        "& .MuiDataGrid-row.Mui-even.Mui-selected": {
            backgroundColor: "#A7A7A7",
            "&:hover": {
                backgroundColor: "#A7A7A7",
            },
        },
        "& .MuiDataGrid-row.Mui-odd.Mui-selected": {
            backgroundColor: "#A7A7A7",
            "&:hover": {
                backgroundColor: "#A7A7A7",
            },
        },
    },
    xgrid: {
        position: "relative",
        height: "90%",
    },
    paginador: {
        position: "absolute",
        bottom: "0.9em",
        left: "15em",
        width: "5%",
    },
}));
