import React, { useEffect, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageFormatTypes, setImageFormatTypes] = useState([]);
    const [imageFormatTypesIndexes, setImageFormatTypesIndexes] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const getImageFormatTypes = async () => {
        const body = {
            service: "FormatoXTipoDeImagenGet",
            params: {
                formatoXTipoImagenId: null,
                tipoDeImagenId: null,
                formatoImagenId: null,
            },
        };

        const imageFormatTypesResponse = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setImageFormatTypes(imageFormatTypesResponse);

        // set countries indexes for formik validation
        imageFormatTypesResponse.map((imageFormatType) => {
            setImageFormatTypesIndexes((imageFormatTypesIndexes) => [
                ...imageFormatTypesIndexes,
                imageFormatType.formatoXTipoImagenId,
            ]);
        });
    };

    const handleSubmit = async ({
        imagenVariaCod,
        imagenVariaNombre,
        formatoXTipoImagenId,
        imagenVariaFilename,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ImagenVariaIns",
            params: {
                imagenVariaCod: String(imagenVariaCod).toUpperCase(),
                imagenVariaNombre: imagenVariaNombre,
                formatoXTipoImagenId: formatoXTipoImagenId,
                imagenVariaFilename: imagenVariaFilename,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        getImageFormatTypes();
    }, []);

    return (
        <Formik
            initialValues={{
                imagenVariaCod: "",
                imagenVariaNombre: "",
                formatoXTipoImagenId: "",
                imagenVariaFilename: "",
            }}
            validationSchema={Yup.object({
                imagenVariaCod: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(10, "Máximo 10 caracteres"),
                imagenVariaNombre: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(45, "Máximo 45 caracteres"),
                formatoXTipoImagenId: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(
                        imageFormatTypesIndexes,
                        "Formato de tipo de imagen inválido"
                    ),
                imagenVariaFilename: Yup.string(
                    FORM_ERROR_MESSAGES.text
                ).required(FORM_ERROR_MESSAGES.required),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <FormikTextInput
                        fullWidth={true}
                        name="imagenVariaCod"
                        labelText={"Código"}
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="imagenVariaNombre"
                        labelText={"Nombre"}
                    />

                    <FormikSelectInput
                        disabled={!imageFormatTypes.length}
                        fullWidth={true}
                        name="formatoXTipoImagenId"
                        labelText={"Formato de Tipo de imagen"}
                    >
                        <option value="">Seleccione una opción...</option>
                        {imageFormatTypes?.map(
                            ({ formatoXTipoImagenId, tipoDeImagenNombre }) => (
                                <option
                                    key={formatoXTipoImagenId}
                                    value={formatoXTipoImagenId}
                                >
                                    {tipoDeImagenNombre}
                                </option>
                            )
                        )}
                    </FormikSelectInput>

                    <FormikTextInput
                        fullWidth={true}
                        name="imagenVariaFilename"
                        labelText={"Folder"}
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Enviar" }}
                        hasConfirmationModal={true}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
