import axiosPost from "../axiosPost";

export const endUserCuilCuitGet = async (arg) => {
    const { params } = arg;

    const body = {
        service: "EndUserOnboardCUILBOGet",
        params: params ? params : {},
    };

    try {
        const result = await axiosPost("Y", body, arg);
        return result.result[0];
    } catch (err) {
        console.log(err);
    }
};
