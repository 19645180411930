import axiosPost from "./axiosPost";

export const usuarioMinimosGet = async (
    credentials,
    setSnackbarInfo = () => {}
) => {
    const body = {
        service: "UsuarioMinimosGet",
        params: {},
    };

    try {
        const result = await axiosPost("Y", body, credentials);

        if (result?.status?.errcode) {
            setSnackbarInfo({
                message: result?.status?.errmsg,
                severity: "error",
                open: true,
            });
            setTimeout(() => {
                setSnackbarInfo({ open: false });
            }, 2500);

            return;
        }

        return result.result[0];
    } catch (e) {
        setSnackbarInfo({
            message: e.errmsg,
            severity: "error",
            open: true,
        });
        setTimeout(() => {
            setSnackbarInfo((data) => !data.open);
        }, 1500);
    }
};
