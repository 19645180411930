import {
    OnBoardingManualFormArgentina,
    OnBoardingManualFormColombia,
    OnBoardingManualFormPanama,
} from "./index";

export const onBoardingForms = {
    ARG: {
        form: OnBoardingManualFormArgentina,
    },
    COL: {
        form: OnBoardingManualFormColombia,
    },
    PAN: {
        form: OnBoardingManualFormPanama,
    },
};
