import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import GgleMap from "components/UI/Map/GoogleMap";

import { FormLabel } from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responsePolygons, setResponsePolygons] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const handleSubmit = async ({
        zonaNoEnrollNombre,
        zonaNoEnrollEnroll,
        zonaNoEnrollLogin,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ZonaNoEnrollIns",
            params: {
                zonaNoEnrollNombre: zonaNoEnrollNombre,
                zonaNoEnrollEnroll: zonaNoEnrollEnroll,
                zonaNoEnrollLogin: zonaNoEnrollLogin,
                zonaNoEnrollCoordenadas: String(responsePolygons),
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    // const polygonTest = [[{ "lat": -34.638444445938006, "lng": -58.48492940971883 }, { "lat": -34.64098664211151, "lng": -58.472569790578206 }, { "lat": -34.63399541512358, "lng": -58.45257124016317 }, { "lat": -34.62855738671987, "lng": -58.4487946898702 }, { "lat": -34.62128258361438, "lng": -58.458321896291096 }, { "lat": -34.6237546756275, "lng": -58.46879324028524 }, { "lat": -34.62799237631421, "lng": -58.46973737785848 }]];

    return (
        <Formik
            initialValues={{
                zonaNoEnrollNombre: "",
                zonaNoEnrollEnroll: "",
                zonaNoEnrollLogin: "",
                zonaNoEnrollCoordenadas: [],
            }}
            validationSchema={Yup.object().shape(
                {
                    zonaNoEnrollNombre: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).required(FORM_ERROR_MESSAGES.required),
                    zonaNoEnrollEnroll: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).when(["zonaNoEnrollLogin"], {
                        is: (zonaNoEnrollLogin) => zonaNoEnrollLogin === "N",
                        then: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(
                                ["Y"],
                                'Al menos Enroll o Login deben ser "Sí"'
                            ),
                        otherwise: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(["Y", "N"], "Valor inválido"),
                    }),
                    zonaNoEnrollLogin: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).when(["zonaNoEnrollEnroll"], {
                        is: (zonaNoEnrollEnroll) => zonaNoEnrollEnroll === "N",
                        then: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(
                                ["Y"],
                                'Al menos Enroll o Login deben ser "Sí"'
                            ),
                        otherwise: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(["Y", "N"], "Valor inválido"),
                    }),
                },
                ["zonaNoEnrollLogin", "zonaNoEnrollEnroll"]
            )}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ touched }) => (
                <Form className={styles.inputs} noValidate>
                    <div className={styles.column}>
                        <FormikTextInput
                            fullWidth={true}
                            name="zonaNoEnrollNombre"
                            labelText={"Nombre"}
                        />

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="zonaNoEnrollEnroll"
                            labelText={"Enroll"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="zonaNoEnrollLogin"
                            labelText={"Login"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        {/* Map and error handling */}
                        <FormLabel
                            sx={{
                                marginTop: "0.5em",
                                textAlign: "start",
                                width: "100%",
                            }}
                        >
                            Zona a denegar
                        </FormLabel>
                        <GgleMap
                            setResponsePolygons={setResponsePolygons}
                            polygonPaths={responsePolygons}
                            enableDrawingManager={true}
                            width={820}
                            height={300}
                        />
                        {touched.zonaNoEnrollCoordenadas &&
                            !responsePolygons.length && (
                                <p
                                    name="zonaNoEnrollCoordenadas"
                                    className={styles.mapErrorMsg}
                                >
                                    Seleccione una zona a denegar
                                </p>
                            )}

                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Enviar" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                </Form>
            )}
        </Formik>
    );
}
