import React from "react";

import { Button } from "@mui/material";

import { useRecoilValue, useRecoilState } from "recoil";
import { modalData, snackbarData } from "recoil/GlobalState";

import { FormButtonsStyle } from "styles/components/UI/FormButtons.styles";

export default function FormButton({
    closeButtonText,
    hasConfirmationModal,
    handleSubmitAction,
    inRequest,
    isConfirmationModal,
    newFormData,
    setSecondaryModalDetails,
    hideCancelButton = false,
}) {
    const { open } = useRecoilValue(snackbarData);
    const [modal, setModal] = useRecoilState(modalData);

    const styles = FormButtonsStyle();

    const className =
        (!isConfirmationModal && modal.title?.includes("Eliminar")) ||
            modal.title?.includes("Cancelar") || newFormData?.button.includes("Confirmar")
            ? styles.redBtn
            : styles.defaultBtn;

    return (
        <div className={styles.container}>
            <Button
                type="submit"
                disabled={inRequest || open}
                className={className}
                onClick={handleSubmitAction}
            >
                <span>{newFormData ? newFormData.button : modal.button}</span>
            </Button>

            <Button
                style={{ display: hideCancelButton ? "none" : "" }}
                disabled={inRequest || open}
                className={styles.whiteBtn}
                onClick={
                    setSecondaryModalDetails
                        ? () => setSecondaryModalDetails({ isOpen: false })
                        : () =>
                            /* Checking if the modal is a confirmation modal, if it is, it will close
                          the confirmation modal. If it is not, it will check if the modal has a
                          confirmation modal, if it does, it will open the confirmation modal. If
                          it does not, it will close the modal. */
                            isConfirmationModal
                                ? setModal({
                                    ...modal,
                                    confirmationModalOpen: false,
                                })
                                : hasConfirmationModal
                                    ? setModal({
                                        ...modal,
                                        confirmationModalOpen: true,
                                    })
                                    : setModal({
                                        isOpen: false,
                                    })
                }
            >
                <span>{closeButtonText ? closeButtonText : "Cancelar"}</span>
            </Button>
        </div>
    );
}
