import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/components/UI/ModalForm.module.scss";
import moment from "moment";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [feriados, setFeriados] = useState([]);

    const [searchParams] = useSearchParams();
    const feriadoId = searchParams.get("feriado_id");

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const feriadosGet = async (feriadoId = null, paisId = null) => {
        const body = {
            service: "FeriadoGet",
            params: {
                feriadoId: feriadoId,
                paisId: paisId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setFeriados(result);
    };

    useEffect(() => {
        feriadosGet();
    }, []);

    const handleSubmit = async ({
        feriadoId,
        feriadoSerieFecha,
        feriadoSerieEsComercial,
        feriadoSerieEsBancario,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "FeriadoSerieIns",
            params: {
                feriadoId: Number(feriadoId),
                feriadoSerieFecha:
                    moment(feriadoSerieFecha).format("YYYY-MM-DD"),
                feriadoSerieEsComercial: feriadoSerieEsComercial,
                feriadoSerieEsBancario: feriadoSerieEsBancario,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                feriadoId: feriadoId,
                feriadoSerieFecha: "",
                feriadoSerieEsComercial: "",
                feriadoSerieEsBancario: "",
            }}
            validationSchema={Yup.object({
                feriadoId: Yup.string(FORM_ERROR_MESSAGES.text).required(
                    FORM_ERROR_MESSAGES.required
                ),
                feriadoSerieFecha: Yup.string(
                    FORM_ERROR_MESSAGES.text
                ).required(FORM_ERROR_MESSAGES.required),
                feriadoSerieEsComercial: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(["Y", "N"]),
                feriadoSerieEsBancario: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(["Y", "N"]),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                {!feriados.length ? (
                    <CircularIndeterminate />
                ) : (
                    <div className={styles.column}>
                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="feriadoId"
                            labelText={"Feriados"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {feriados?.map(({ feriadoId, feriadoNombre }) => (
                                <option key={feriadoId} value={feriadoId || ""}>
                                    {feriadoNombre}
                                </option>
                            ))}
                        </FormikSelectInput>

                        <FormikTextInput
                            fullWidth={true}
                            name="feriadoSerieFecha"
                            labelText={"Fecha"}
                            type="date"
                        />

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="feriadoSerieEsComercial"
                            labelText={"Es Comercial"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="feriadoSerieEsBancario"
                            labelText={"Es Bancario"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Enviar" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                )}
            </Form>
        </Formik>
    );
}
