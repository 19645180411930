import { makeStyles } from "@material-ui/core/styles";

export const ListDrawerStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& .MuiFormControl-root": {
            width: "100%",
        },
        "& .MuiInputBase-root::before": {
            borderBottom: "1px solid white",
        },
        "& .MuiNativeSelect-select, .MuiSvgIcon-root": {
            fontSize: "1.2em",
        },
        "& .MuiTextField-root": {
            marginLeft: "-1.5em",
            width: "90%",
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
        display: "flex",
    },
    iconsDrawer: {
        width: "1.6rem",
    },
    itemList: {
        paddingBottom: theme.spacing(2),
    },
    link: {
        textDecoration: "none",
    },
    textColor: {
        color: "#ffffff",
        fontFamily: "Roboto, sans-serif",
        fontWeight: "300",
        fontSize: "0.85rem",
        flexGrow: 1,
    },
    selectList: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: theme.spacing(4),
    },
}));
