import React, { useEffect } from "react";

import { Typography } from "@mui/material";

import { ROUTES } from "consts/routes";

import { useSetRecoilState } from "recoil";
import { routeInfo } from "recoil/GlobalState";

import MIALogo from 'assets/img/new-logo-mia.svg';

import styles from 'styles/pages/Home.module.scss';

const Dashboard = () => {
    const setRoute = useSetRecoilState(routeInfo);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, [window.location.href]);

    return (
        <section className={styles.homeContainer}>
            <div className={styles.background}>
                <Typography variant="h2">
                    BackOffice Usuarios de
                </Typography>

                <img src={MIALogo} alt="logo"/>
            </div>
        </section>
    );
};

export default Dashboard;
