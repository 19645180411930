import React, { forwardRef, useState } from 'react';

const ZoomableImage = forwardRef(function ZoomableImage(props, ref) {
    const { alt, className, src } = props;
    const [zoomLevel, setZoomLevel] = useState(1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isFrozen, setIsFrozen] = useState(true);
    const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });

    const handleImageZoom = () => {
        let newZoomLevel;
        if (zoomLevel === 1) {
            newZoomLevel = 2;
        } else if (zoomLevel === 2) {
            newZoomLevel = 4;
        } else if (zoomLevel === 4) {
            newZoomLevel = 8;
        }
        else {
            newZoomLevel = 1;
            setPosition({ x: 0, y: 0 });
        }
        setZoomLevel(newZoomLevel);
    };

    const handleMouseMove = (event) => {
        if (isFrozen || zoomLevel === 1) return;
        console.log(dragStartPosition, event.clientX, event.clientY);

        const deltaX = dragStartPosition.x - event.clientX;
        const deltaY = dragStartPosition.y - event.clientY;

        setPosition((prevPosition) => ({
            x: prevPosition.x - deltaX,
            y: prevPosition.y - deltaY,
        }));

        setDragStartPosition({ x: event.clientX, y: event.clientY });
    };

    const handleImageFrozen = (event) => {
        if (zoomLevel === 1) return;
        setDragStartPosition({ x: event.clientX, y: event.clientY });
        setIsFrozen(!isFrozen);
    };

    return (
        <div
            style={
                {
                    position: "relative",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%"
                }
            }
            onClick={handleImageFrozen}
            onDoubleClick={handleImageZoom}
            onMouseMove={handleMouseMove}
        >
            <img
                src={src}
                alt={alt}
                className={className}
                ref={ref}
                style={{
                    transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                    transition: 'transform 0.3s ease',
                }}
            />
            <span style={
                {
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    color: "#fff",
                    textShadow: `
                        1px 1px 0 #000,
                        -1px -1px 0 #000,  
                        1px -1px 0 #000,
                        -1px 1px 0 #000,
                        1px 1px 0 #000`,
                }
            }
            >
                x{zoomLevel}
            </span>
        </div>
    );
});

export default ZoomableImage;