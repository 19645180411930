import React from "react";

import { Grid } from "@mui/material";

import GgleMap from "components/UI/Map/GoogleMap";

import { FingerPrint } from "assets/icons";

import styles from "styles/pages/usuarios/UserDetailsDashboard.module.scss";

export const PersonalDataDashboard = ({ user }) => {
    return (
        <Grid
            container
            columns={12}
        >
            <Grid item sm={2}>
                <img
                    src={user?.imagePathSELFIE}
                    alt="selfie"
                    className={styles.selfie}
                />
            </Grid>

            <Grid className={styles.dataContainer} item sm={6}>
                <div className={styles.personalDataDiv}>
                    <p className={styles.paragraph}>
                        <span className={styles.title}>Fecha de emisión</span>
                        <span className={styles.data}>
                            {user?.usuarioFechaDeNacimiento}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>
                            Fecha de nacimiento
                        </span>
                        <span className={styles.data}>
                            {user?.usuarioFechaDeNacimiento}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Email</span>
                        <span className={styles.data}>
                            {user?.usuarioMail || "-"}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>CUIT</span>
                        <span className={styles.data}>
                            {user?.usuarioCUIT}
                        </span>
                    </p>
                </div>

                <div className={styles.personalDataDiv}>
                    <p className={styles.paragraph}>
                        <span className={styles.title}>
                            Número de documento
                        </span>
                        <span className={styles.data}>
                            {user?.usuarioNroDeDocumento}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Sexo</span>
                        <span className={styles.data}>
                            {user?.usuarioSexo === "M"
                                ? "Masculino"
                                : user?.usuarioSexo === "F"
                                    ? "Femenino"
                                    : "No Binario"}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Celular</span>
                        <span className={styles.data}>
                            {user?.usuarioTelefonoCelular || "-"}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>NDI</span>
                        <span className={styles.data}>-</span>
                    </p>
                </div>
            </Grid>

            <Grid item sm={4}>
                <div className={styles.card}>
                    <h2>Último login</h2>
                    <p>{user?.ultimaSesion || 'No diponible'}</p>
                    <img src={FingerPrint} alt="fingerprint" />
                </div>
            </Grid>

            <Grid className={styles.dataContainer} item sm={6}>
                <div className={styles.personalDataDiv}>
                    <p className={styles.paragraph}>
                        <span className={styles.title}>Calle</span>
                        <span className={styles.data}>
                            {user?.domicilioCalle}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Número</span>
                        <span className={styles.data}>
                            {user?.domicilioCasa}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Piso</span>
                        <span className={styles.data}>
                            {user?.domicilioPiso || "-"}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Departamento</span>
                        <span className={styles.data}>
                            {user?.domicilioUnidad || "-"}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Código postal</span>
                        <span className={styles.data}>
                            {user?.domicilioCPA}
                        </span>
                    </p>
                </div>

                <div className={styles.personalDataDiv}>
                    <p className={styles.paragraph}>
                        <span className={styles.title}>Provincia</span>
                        <span className={styles.data}>
                            {user?.provinciaNombre}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Localidad</span>
                        <span className={styles.data}>
                            {user?.localidadNombre}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Latitud</span>
                        <span className={styles.data}>
                            {user?.primeraSesionLatitud || "-"}
                        </span>
                    </p>

                    <p className={styles.paragraph}>
                        <span className={styles.title}>Longitud</span>
                        <span className={styles.data}>
                            {user?.primeraSesionLongitud || "-"}
                        </span>
                    </p>
                </div>
            </Grid>

            <Grid item sm={4} >
                {
                    user?.primeraSesionLatitud && user?.primeraSesionLongitud
                        ?
                        <div>
                            <span className={styles.geoLocation}>
                                <GgleMap
                                    lat={user?.primeraSesionLatitud}
                                    lng={user?.primeraSesionLongitud}
                                    customCenter={{
                                        lat: user?.primeraSesionLatitud,
                                        lng: user?.primeraSesionLongitud,
                                    }}
                                />
                            </span>
                            <p>Geolocalización registrada</p>
                        </div>
                        :
                        <span className={styles.geoLocation}>
                            Geolocalización no registrada
                        </span>
                }
            </Grid>
        </Grid>
    );
};
