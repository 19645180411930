import { React } from "react";
import { Link } from "react-router-dom";

import { List, ListItem, ListItemIcon } from "@mui/material";

import { InsideDrawerNav } from "./InsideDrawerNav";

import IconTables from "assets/icons/icon-tables.svg";

import { INSIDE_DRAWER, ROUTES } from "consts/routes";

import { useSetRecoilState } from "recoil";
import { routeInfo } from "recoil/GlobalState";

import { ListDrawerStyles } from "styles/components/List/ListDrawer.styles";

export default function ListDrawer({ setOpen }) {
    const styles = ListDrawerStyles();

    const setRoute = useSetRecoilState(routeInfo);

    const handleClick = (route) => {
        setRoute(route);
        setOpen(false);
    };

    const AvailableRoutes = (() => {
        /* Sorting the routes alphabetically. */
        let partialRoutes = [];

        for (let route in ROUTES) {
            partialRoutes.push([route, ROUTES[route]]);
        }

        // order alphabetically
        partialRoutes.sort(function (a, b) {
            if (a[1].wordKey < b[1].wordKey) {
                return -1;
            }
            if (a[1].wordKey > b[1].wordKey) {
                return 1;
            }
            return 0;
        });

        let finalRoutes = {};

        partialRoutes.forEach(function (item) {
            finalRoutes[item[0]] = item[1];
        });

        return finalRoutes;
    })();

    return (
        <>
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={styles.root}
            >
                {Object.values(AvailableRoutes).map(
                    (route) =>
                        route.showMenuItem &&
                        !route.insideDrawer && (
                            <Link
                                className={styles.link}
                                key={route.route}
                                to={route.route}
                            >
                                <ListItem
                                    button
                                    className={styles.itemList}
                                    onClick={() => handleClick(route)}
                                >
                                    <ListItemIcon>
                                        <img
                                            className={styles.iconsDrawer}
                                            src={IconTables}
                                            alt="Tables"
                                        />
                                    </ListItemIcon>
                                    <span className={styles.textColor}>
                                        {route.wordKey}
                                    </span>
                                </ListItem>
                            </Link>
                        )
                )}
            </List>

            {/* Onboarding */}
            {Object.values(INSIDE_DRAWER).map((drawer, index) => (
                <InsideDrawerNav
                    availableRoutes={AvailableRoutes}
                    drawer={drawer}
                    handleClick={handleClick}
                    key={index}
                />
            ))}
        </>
    );
}
