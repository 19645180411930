import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { feriadoSerie as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
    breadcrumbState,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function FeriadosSeries() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const [searchParams] = useSearchParams();

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setBreadCrumbs = useSetRecoilState(breadcrumbState);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [feriadoId, setFeriadoId] = useState("");
    const [feriadoSeriesLocalState, setFeriadoSeriesLocalState] = useState([]);

    const handleUpdateTable = async (feriadoSelectedId = feriadoId) => {
        await formatFeriadoSerie(feriadoSelectedId);
        setRow({ status: false, row: {} });
    };

    const formatFeriadoSerie = async (feriadoId) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "FeriadoSerieGet",
            params: {
                feriadoId: Number(feriadoId),
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const feriadoSeriesPartial = result.reduce((acc, item) => {
            const {
                feriadoSerieId,
                feriadoId,
                feriadoNombre,
                feriadoSerieFecha,
                feriadoSerieEsComercial,
                feriadoSerieEsBancario,
                feriadoSerieUID,
                feriadoSerieUFH,
                feriadoSerieVersion,
            } = item;

            const feriadoSerie = {
                id: feriadoSerieId,
                feriadoId: feriadoId,
                feriadoNombre: feriadoNombre,
                feriadoSerieFecha: feriadoSerieFecha,
                feriadoSerieEsComercial: feriadoSerieEsComercial,
                feriadoSerieEsBancario: feriadoSerieEsBancario,
                feriadoSerieUID: feriadoSerieUID,
                feriadoSerieUFH: feriadoSerieUFH,
                feriadoSerieVersion: feriadoSerieVersion,
                [titles[0]]: feriadoNombre,
                [titles[1]]: feriadoSerieFecha,
                [titles[2]]: feriadoSerieEsComercial === "Y" ? "Sí" : "No",
                [titles[3]]: feriadoSerieEsBancario === "Y" ? "Sí" : "No",
            };

            return [...acc, feriadoSerie];
        }, []);

        setFeriadoSeriesLocalState(feriadoSeriesPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;

        const feriadoId = searchParams.get("feriado_id");

        // prevent visiting page without feriado id
        if (!feriadoId) {
            window.location.replace(ROUTES.feriados.route);
        }

        setFeriadoId(feriadoId);
        formatFeriadoSerie(feriadoId);
    }, [credentials]);

    useEffect(() => {
        setBreadCrumbs([
            { name: ROUTES.feriados.wordKey, route: ROUTES.feriados.route },
            {
                name: ROUTES.feriados_series.wordKey,
                route: ROUTES.feriados_series.route,
            },
        ]);

        setRow({ status: false, row: {} });
    }, []);

    useEffect(() => {
        setRoute(ROUTES.feriados_series.route);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Serie de Feriado - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Serie de Feriado - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Serie de Feriado - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={feriadoSeriesLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
