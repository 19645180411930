import React, { useEffect, useState } from "react";
import { Box, Grid, InputLabel } from "@mui/material";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { base64Convert } from "utils/base64Convert";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [docTypes, setDocTypes] = useState([]);
    const [paises, setPaises] = useState([]);
    const [documentSpecs, setDocumentSpecs] = useState("");
    const [isDocNumberDisabled, setIsDocNumberDisabled] = useState(false);
    const [isCountryDisabled, setIsCountryDisabled] = useState(false);
    const [isDocTypeDisabled, setIsDocTypeDisabled] = useState(false);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const getPaises = async () => {
        setIsLoading(true);

        const body = {
            service: "MIAPaisGet",
            params: {},
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setPaises(result);

        setIsLoading(false);
    };

    const getDocType = async (paisId = 1) => {
        const body = {
            service: "TipoDeDocumentoGet",
            params: {
                paisId: paisId
            },
        };
        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        console.log(result);
        setDocTypes(result);
    };

    const handleSubmit = async ({ tipoDeDocumentoId,
        usuarioNroDeDocumentoX,
        usuarioFotoImg,
        usuarioSexo,
        unknown_doc_number }) => {

        setInRequest(true);
        setIsLoading(true);

        if (unknown_doc_number) {
            tipoDeDocumentoId = null;
            usuarioNroDeDocumentoX = null;
        }

        const body = {
            service: "UsuarioBlacklistOMNIIns",
            params: {
                tipoDeDocumentoId: Number(tipoDeDocumentoId),
                usuarioFotoImg: usuarioFotoImg.split("data:image/jpeg;base64,")[1],
                usuarioNroDeDocumentoX: usuarioNroDeDocumentoX,
                usuarioSexo: usuarioSexo
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest(false);
        setIsLoading(false);
    };

    const unknownDocNumber = (event, setFieldValue) => {
        if (event.target.checked) {
            setFieldValue('unknown_doc_number', event.target.checked);
            setFieldValue('tipoDeDocumentoId', null);
            setFieldValue('usuarioNroDeDocumentoX', null);
            setIsCountryDisabled(true);
            setIsDocNumberDisabled(true);
            setIsDocTypeDisabled(true);
        } else {
            setIsCountryDisabled(false);
            setIsDocNumberDisabled(false);
            setIsDocTypeDisabled(false);
        }
    };

    useEffect(() => {
        credentials.length ?? getPaises();
    }, [credentials]);

    return (
        <Formik
            initialValues={{
                tipoDeDocumentoId: "",
                usuarioFotoImg: "",
                unknown_doc_number: false,
                usuarioNroDeDocumentoX: "",
                usuarioSexo: ""
            }}
            validationSchema={Yup.object({
                unknown_doc_number: Yup.bool(),
                tipoDeDocumentoId: Yup.number(FORM_ERROR_MESSAGES.number)
                    .when(["unknown_doc_number"], {
                        is: (unknown_doc_number) => unknown_doc_number === true,
                        then: Yup.number(FORM_ERROR_MESSAGES.number).nullable(),
                        otherwise: Yup.number(FORM_ERROR_MESSAGES.number)
                            .required(FORM_ERROR_MESSAGES.required)
                    }),
                usuarioNroDeDocumentoX: Yup.string(FORM_ERROR_MESSAGES.text)
                    .when(["unknown_doc_number"], {
                        is: (unknown_doc_number) => unknown_doc_number === true,
                        then: Yup.string(FORM_ERROR_MESSAGES.text).nullable(),
                        otherwise: Yup.string(FORM_ERROR_MESSAGES.text).test(
                            "match",
                            "Documento inválido",
                            (value) =>
                                String(value).match(
                                    documentSpecs.tipoDeDocumentoRegExp
                                )
                        ).required(FORM_ERROR_MESSAGES.required)
                    }),
                usuarioSexo: Yup.string(FORM_ERROR_MESSAGES.text).oneOf(["M", "F", "X"])
                    .required(FORM_ERROR_MESSAGES.required)
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {(({ setFieldValue, values }) =>
                <Form className={styles.inputs} noValidate>
                    <div className={styles.column}>
                        <Grid container columns={12} justifyContent={"center"} alignItems="center" width={"100%"}>
                            <Grid item md={9}>
                                <Grid container columns={12} gap={3} justifyContent={"center"} width={"100%"}>
                                    <Grid item md={5}>
                                        <FormikSelectInput
                                            disabled={isCountryDisabled || !paises.length}
                                            fullWidth
                                            id="paisId"
                                            labelText={"País"}
                                            name="paisId"
                                            onChange={(e) => getDocType(Number(e.target.value))}
                                        >
                                            <option value={""}>
                                                Seleccione una opción...
                                            </option>
                                            {paises.map(
                                                ({
                                                    paisId,
                                                    paisNombre
                                                }) => (
                                                    <option
                                                        key={paisId}
                                                        data-id={paisId}
                                                        value={paisId}
                                                    >
                                                        {paisNombre}
                                                    </option>
                                                )
                                            )}
                                        </FormikSelectInput>
                                    </Grid>

                                    <Grid item md={5}>
                                        <FormikSelectInput
                                            disabled={isDocTypeDisabled || !docTypes.length}
                                            fullWidth
                                            id="tipoDeDocumentoId"
                                            labelText={"Tipo de documento"}
                                            name="tipoDeDocumentoId"
                                            variant="standard"
                                            onChange={(e) => {
                                                setFieldValue("tipoDeDocumentoId", e.target.value),
                                                    setDocumentSpecs(
                                                        docTypes.filter((doc) => String(doc.tipoDeDocumentoId) === e.target.value)[0]
                                                    );
                                            }}
                                        >
                                            <option value="">Seleccione una opcion...</option>
                                            {docTypes.map(({ tipoDeDocumentoId, tipoDeDocumentoNombre }) => (
                                                <option value={tipoDeDocumentoId} key={tipoDeDocumentoId}>{tipoDeDocumentoNombre}</option>
                                            ))}
                                        </FormikSelectInput>
                                    </Grid>

                                    <Grid item md={5} gap={2}>
                                        <FormikTextInput
                                            disabled={isDocNumberDisabled}
                                            fullWidth={true}
                                            helperText={documentSpecs.tipoDeDocumentoPlaceholder}
                                            labelText={"Número de documento"}
                                            name="usuarioNroDeDocumentoX"
                                            type={'tel'}
                                        />

                                        <Box alignItems={'center'} display={'flex'} justifyContent={'start'} gap={1} marginLeft={1} marginTop={1} width={'100%'}>
                                            <input
                                                onChange={e => unknownDocNumber(e, setFieldValue)}
                                                id="unknown_doc_number"
                                                name="unknown_doc_number"
                                                type="checkbox"
                                            />

                                            <label htmlFor="unknown_doc_number">
                                                Número de documento desconocido
                                            </label>
                                        </Box>
                                    </Grid>

                                    <Grid item md={5}>
                                        <FormikSelectInput
                                            fullWidth
                                            labelText={"Sexo"}
                                            name="usuarioSexo"
                                            id="usuarioSexo"
                                            variant="standard"
                                        >
                                            <option value="">Seleccione una opcion...</option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                            <option value="X">No Binario</option>
                                        </FormikSelectInput>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3} justifyContent={"center"} gap={2} display={"flex"} flexWrap={"wrap"}>
                                <InputLabel>Imagen</InputLabel>

                                <Box width={"100%"} textAlign={"center"}>
                                    <input name="usuarioFotoImg" type="file" accept="image/*" onChange={(e) => base64Convert(e, setFieldValue, "usuarioFotoImg", "image/jpeg")} />
                                </Box>
                                {
                                    values.usuarioFotoImg && <img alt="usuario imagen" className={styles.usuarioFotoImg} src={values.usuarioFotoImg} title="" />
                                }
                                <ErrorMessage className={styles.errorMsg} component={"p"} name="usuarioFotoImg" />
                            </Grid>
                        </Grid>


                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Crear" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                </Form>
            )
            }
        </Formik >
    );
}
