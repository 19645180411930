export function base64Convert(
    src,
    setFieldValue,
    fieldName,
    format = "image/png"
) {
    const imgUrl = URL.createObjectURL(src.target.files[0]);

    let img = new Image();
    img.crossOrigin = "anonymous";

    img.onload = function () {
        const canvas = document.createElement("canvas");

        const ctx = canvas.getContext("2d");

        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;

        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL(format);

        setFieldValue(fieldName, dataURL);
    };

    img.src = imgUrl;
}
