import { ACTIONS } from "consts/actions";
import { ACTION_REQUEST_MESSAGES } from "consts/errorsMessages";
import axiosPost from "./axiosPost";

export const RequestServiceActions = async (
    body,
    credentials,
    action,
    setSnackbarInfo = () => { }
) => {
    const result = await axiosPost("Y", body, credentials);
    const status = result.status;
    let successMessage;

    switch (action) {
        case ACTIONS.UPDATE:
            successMessage = ACTION_REQUEST_MESSAGES.UPDATE_SUCCESS;
            break;

        case ACTIONS.INSERT:
            successMessage = ACTION_REQUEST_MESSAGES.INSERT_SUCCESS;
            break;

        case ACTIONS.DELETE:
            successMessage = ACTION_REQUEST_MESSAGES.DELETE_SUCCESS;
            break;

        default:
            successMessage = ACTION_REQUEST_MESSAGES.DEFAULT;
            break;
    }

    if (status?.errcode && status?.errmsg) {
        setSnackbarInfo({
            message: status.errmsg,
            severity: "error",
            open: true,
        });
    } else {
        setSnackbarInfo({
            message: successMessage,
            severity: "success",
            open: true,
        });
    }
    return result;
};
