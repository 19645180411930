import React, { useEffect, useState } from "react";

import { rubro as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function Rubros() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [mailTypesLocalState, setRubrosLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatRubros();
        setRow({ status: false, row: {} });
    };

    const formatRubros = async (rubroId = null, rubroCod = null) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "RubroDeMerchantGet",
            params: {
                rubroMerchantId: rubroId,
                rubroMerchantCod: rubroCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const mailTypesPartial = result.reduce((acc, item) => {
            const {
                rubroMerchantId,
                rubroMerchantCod,
                rubroMerchantNombre,
                imagePathRUBROC,
                rubroUID,
                rubroUFH,
                rubroMerchantVersion,
            } = item;

            const mailType = {
                id: rubroMerchantId,
                rubroMerchantCod: rubroMerchantCod,
                rubroMerchantNombre: rubroMerchantNombre,
                imagePathRUBROC: imagePathRUBROC,
                rubroUID: rubroUID,
                rubroUFH: rubroUFH,
                rubroMerchantVersion: rubroMerchantVersion,
                [titles[0]]: rubroMerchantCod,
                [titles[1]]: rubroMerchantNombre,
                [titles[2]]: imagePathRUBROC,
            };

            return [...acc, mailType];
        }, []);

        setRubrosLocalState(mailTypesPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Rubros - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Rubros - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Rubros - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={mailTypesLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
