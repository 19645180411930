import axiosPost from "../axiosPost";

export const endUserLocationGet = async (arg) => {
    const name = "End User Location GET";
    let result;

    const { params } = arg;

    const body = {
        service: "EndUserOnboardDomicilioBOGet",
        params: params ? params : {},
    };

    try {
        result = await axiosPost("Y", body, arg);
    } catch (err) {
        console.log(err);
    }

    if (!result || result === null) {
        console.log(`Error getting ${name}`);
    }

    return result.result[0];
};
