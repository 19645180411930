import React, { useState, useEffect } from "react";

import editar from "assets/icons/editar.svg";
import modalEditar from "assets/icons/modal-editar.svg";

import TransitionModal from "components/UI/Modals/Modal";

import {
    CheckCircleOutlineOutlined as CheckIcon,
    CancelOutlined as CancelIcon,
} from "@material-ui/icons";

import CircularIndeterminate from "components/UI/Spinner/Spinner";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

import {
    userCredentials,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import styles from "styles/pages/usuarios/EnrollDataDashBoard.module.scss";
import { ESTADO_REGISTRO_COD } from "consts/estadoRegistroCod";

import LocationModal from "./Location/LocationModal";
import CuilCuitModal from "./CUIL-CUIT/Cuil-CuitModal";
import IdentityModal from "./Identity/IdentityModal";
import OmniModal from "./Omni/OmniModal";

export default function EnrollDataDashboard({ userId }) {
    const [userStatus, setUserStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [procede, setProcede] = useState(null);

    const [modalDetails, setModalDetails] = useRecoilState(modalData);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const credentials = useRecoilValue(userCredentials);

    async function retrieveUserStatus() {
        setIsLoading(true);

        const body = {
            service: "UsuarioStatusGet",
            params: {
                usuarioId: userId || "",
                rolCod: "E",
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setUserStatus(result);
        setIsLoading(false);
    }

    useEffect(() => {
        if (!credentials) return;
        retrieveUserStatus();
    }, [credentials]);

    const closeModal = () => {
        setProcede(null);
        setModalDetails({ ...modalDetails, isOpen: false });
    };

    const openModal = (procede) => {
        setProcede(procede);
        setModalDetails({ ...modalDetails, isOpen: true });
    };

    const codsRegistros = [
        "EU0002",
        "EU0003",
        "EU0004",
        "EU0005",
        "EU0006",
        "EU0015"
    ];

    if (isLoading) return (<CircularIndeterminate />);
    return (
        <div>
            <TransitionModal customOnClose={closeModal}>
                <>
                    {(procede === "EU0002" ||
                        procede === "EU0003" ||
                        procede === "EU0004") && (
                            <IdentityModal
                                closeModal={closeModal}
                                updateModalList={retrieveUserStatus}
                                userId={userId}
                            />
                        )}
                    {procede === "EU0005" && (
                        <LocationModal
                            closeModal={closeModal}
                            updateModalList={retrieveUserStatus}
                            userId={userId}
                        />
                    )}
                    {procede === "EU0006" && (
                        <CuilCuitModal
                            closeModal={closeModal}
                            updateModalList={retrieveUserStatus}
                            userId={userId}
                        />
                    )}
                    {procede === "EU0015" && (
                        <OmniModal
                            closeModal={closeModal}
                            updateModalList={retrieveUserStatus}
                            userId={userId}
                        />
                    )}
                </>
            </TransitionModal>

            <div className={styles.container}>
                {userStatus
                    .filter((status) => status.estadoDeRegistroCod !== "F")
                    .map((status) => {
                        return (
                            <div
                                key={`${status.registroCod} ${status.registroId}`}
                                className={styles.result}
                            >
                                <div className={styles.status}>
                                    <div className={styles.icon}>
                                        {status.estadoDeRegistroCod === ESTADO_REGISTRO_COD.aprobado && (
                                            <CheckIcon
                                                fontSize={"large"}
                                                className={styles.checkIcon}
                                            />
                                        )}

                                        {status.estadoDeRegistroCod === ESTADO_REGISTRO_COD.en_espera && (
                                            <img
                                                src={modalEditar}
                                                className={styles.updateIcon}
                                            />
                                        )}

                                        {status.estadoDeRegistroCod === ESTADO_REGISTRO_COD.rechazado && (
                                            <CancelIcon
                                                fontSize={"large"}
                                                className={styles.cancelIcon}
                                            />
                                        )}
                                    </div>

                                    <p className={styles.registro}>
                                        {status.registroNombre}
                                    </p>

                                    {codsRegistros.includes(status.registroCod) && (
                                        <img
                                            className={styles.editIcon}
                                            src={editar}
                                            onClick={() =>
                                                openModal(status.registroCod)
                                            }
                                        />
                                    )}
                                </div>
                                <hr />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}
