import React from "react";
import { Button as MaterialButton } from "@mui/material";

import { SubmitButtonStyles } from "styles/components/UI/SubmitButton.styles";

export default function Button({ texto, type = "submit", ...props }) {
    const styles = SubmitButtonStyles();

    return (
        <MaterialButton type={type} className={styles.root} {...props}>
            {texto}
        </MaterialButton>
    );
}
