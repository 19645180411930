import React, { useState, useEffect, lazy, Suspense } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import { FormLabel } from "@mui/material";

const GgleMap = lazy(() => import("components/UI/Map/GoogleMap"));

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function UpdateModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [responsePolygons, setResponsePolygons] = useState([]);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const handleSubmit = async ({
        zonaNoEnrollId,
        zonaNoEnrollNombre,
        zonaNoEnrollEnroll,
        zonaNoEnrollLogin,
        zonaNoEnrollVersion,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ZonaNoEnrollUpd",
            params: {
                zonaNoEnrollId: zonaNoEnrollId,
                zonaNoEnrollNombre: zonaNoEnrollNombre,
                zonaNoEnrollEnroll: zonaNoEnrollEnroll,
                zonaNoEnrollLogin: zonaNoEnrollLogin,
                zonaNoEnrollCoordenadas: responsePolygons,
                zonaNoEnrollVersion: zonaNoEnrollVersion,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.UPDATE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        // format of coordinates for update request to backend
        let polygonEdges = [];

        row.row.zonaNoEnrollCoordenadasMap.map(({ lat, lng }) => {
            polygonEdges.push([lat, lng].join(";;"));
        });

        setResponsePolygons(polygonEdges.join("||"));
    }, []);

    return (
        <Formik
            initialValues={{
                zonaNoEnrollId: row.row.id,
                zonaNoEnrollNombre: row.row.zonaNoEnrollNombre,
                zonaNoEnrollEnroll: row.row.zonaNoEnrollEnroll,
                zonaNoEnrollLogin: row.row.zonaNoEnrollLogin,
                zonaNoEnrollCoordenadas: row.row.zonaNoEnrollCoordenadasMap,
                zonaNoEnrollVersion: row.row.zonaNoEnrollVersion,
            }}
            /* 
                the shape() method with the dependency array is used to avoid 
                cyclic dependency issue cause of both field validations requiring each other.
                See: https://github.com/jquense/yup/issues/1006
            */
            validationSchema={Yup.object().shape(
                {
                    zonaNoEnrollNombre: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).required(FORM_ERROR_MESSAGES.required),
                    zonaNoEnrollEnroll: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).when(["zonaNoEnrollLogin"], {
                        is: (zonaNoEnrollLogin) => zonaNoEnrollLogin === "N",
                        then: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(
                                ["Y"],
                                'Al menos Enroll o Login deben ser "Sí"'
                            ),
                        otherwise: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(["Y", "N"], "Valor inválido"),
                    }),
                    zonaNoEnrollLogin: Yup.string(
                        FORM_ERROR_MESSAGES.text
                    ).when(["zonaNoEnrollEnroll"], {
                        is: (zonaNoEnrollEnroll) => zonaNoEnrollEnroll === "N",
                        then: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(
                                ["Y"],
                                'Al menos Enroll o Login deben ser "Sí"'
                            ),
                        otherwise: Yup.string()
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(["Y", "N"], "Valor inválido"),
                    }),
                },
                ["zonaNoEnrollLogin", "zonaNoEnrollEnroll"]
            )}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ touched, initialValues }) => (
                <Form className={styles.inputs} noValidate>
                    <div className={styles.column}>
                        <FormikTextInput
                            fullWidth={true}
                            name="zonaNoEnrollNombre"
                            labelText={"Nombre"}
                        />

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="zonaNoEnrollEnroll"
                            labelText={"Enroll"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="zonaNoEnrollLogin"
                            labelText={"Login"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        {/* Map and error handling */}
                        <FormLabel
                            sx={{
                                marginTop: "0.5em",
                                textAlign: "start",
                                width: "100%",
                            }}
                        >
                            Zona a denegar
                        </FormLabel>
                        <Suspense fallback={<CircularIndeterminate />}>
                            <GgleMap
                                setResponsePolygons={setResponsePolygons}
                                polygonPaths={
                                    initialValues.zonaNoEnrollCoordenadas
                                }
                                enableDrawingManager={true}
                                width={820}
                                height={300}
                                zoom={11}
                                customCenter={
                                    initialValues.zonaNoEnrollCoordenadas[0]
                                }
                            />
                            {touched.zonaNoEnrollCoordenadas &&
                                !responsePolygons.length && (
                                    <p
                                        name="zonaNoEnrollCoordenadas"
                                        className={styles.mapErrorMsg}
                                    >
                                        Seleccione una zona a denegar
                                    </p>
                                )}
                        </Suspense>

                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Enviar" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                </Form>
            )}
        </Formik>
    );
}
