import { makeStyles } from "@material-ui/core/styles";

export const SnackbarStyles = makeStyles(() => ({
    root: {
        width: "80%!important",
        top: "150px!important",
        position: "absolute!important",
        "& MuiAlert-standardSuccess": {
            border: "solid 0.1px #0C2136!important",
        },
        "& .MuiAlert-standardError": {
            border: "solid 0.1px #d44141!important",
        },
        "& .MuiAlert-standardWarning": {
            border: "solid 0.1px #b4b0a3!important",
        },
    },
    alert: {
        display: "flex!important",
        justifyContent: "center!important",
        alignItems: "center!important",
        backgroundColor: "#ffffff!important",
        borderRadius: "1rem!important",
        width: "100%!important",
    },
    message: {
        fontWeight: "400!important",
    },
}));
