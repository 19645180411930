import React, { useState, useRef } from "react";
import { XGrid, LicenseInfo } from "@material-ui/x-grid";

import { TextField } from "@mui/material";
import { generateColumns, getGridProps } from "utils/Local-services";

import { paginationState, routeInfo, rowSelected } from "recoil/GlobalState";
import { useRecoilState, useRecoilValue } from "recoil";

import { LICENSE_KEY } from "consts/env";

import { XGridStyles } from "styles/components/UI/Xgrid.styles";

LicenseInfo.setLicenseKey(LICENSE_KEY);

export default function XGridDemo({
    rows,
    titles,
    secondarySelected,
    setSecondarySelected,
    setPageSize,
    loading,
}) {
    const refTable = useRef();
    const [inputValue, setInputValue] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const [selected, setSelected] = useRecoilState(rowSelected);
    const { route } = useRecoilValue(routeInfo);

    const [pagination, setPagination] = useRecoilState(paginationState);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue === "" || parseInt(inputValue) < 1) {
            setPagination(0);
            return setInputValue(1);
        }
        if (parseInt(inputValue) > numberOfPages) {
            setPagination(numberOfPages);
            return setInputValue(numberOfPages + 1);
        }
        return setPagination(parseInt(inputValue) - 1);
    };

    const { row } = secondarySelected ? secondarySelected : selected;
    const columns = generateColumns(titles, route);
    const setter = setSecondarySelected ? setSecondarySelected : setSelected;
    const styles = XGridStyles();

    const gridProps = getGridProps(columns, rows, row, setter, styles, loading);
    return (
        <div className={styles.xgrid}>
            <XGrid
                {...gridProps}
                page={pagination}
                onPageChange={(newPage) => {
                    setInputValue(newPage + 1);
                    setPagination(newPage);
                }}
                onStateChange={(data) => {
                    if (setPageSize && data.state.pagination.pageSize < 20)
                        setPageSize(data.state.pagination.pageSize);
                    setNumberOfPages(data.state.pagination.pageCount - 1);
                }}
                ref={refTable}
                loading={loading}
            />
            {!setPageSize && (
                <form
                    className={styles.paginador}
                    onSubmit={(e) => handleSubmit(e)}
                >
                    <TextField
                        className={styles.pageNumber}
                        max={numberOfPages}
                        onChange={(e) => setInputValue(e.target.value)}
                        value={inputValue}
                    />
                </form>
            )}
        </div>
    );
}
