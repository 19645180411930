import React, { useEffect, useState } from "react";

import { feriados as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";
import lupa from "assets/icons/con-activar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";
import { Link } from "react-router-dom";

export default function Feriados() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [FeriadosLocalState, setFeriadosLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatFeriados();
        setRow({ status: false, row: {} });
    };

    const formatFeriados = async (feriadoId = null) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "FeriadoGet",
            params: {
                feriadoId: feriadoId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const FeriadosPartial = result.reduce((acc, item) => {
            const {
                feriadoId,
                feriadoNombre,
                feriadoUID,
                feriadoUFH,
                feriadoVersion,
            } = item;

            const Feriado = {
                id: feriadoId,
                feriadoNombre: feriadoNombre,
                feriadoUID: feriadoUID,
                feriadoUFH: feriadoUFH,
                feriadoVersion: feriadoVersion,
                [titles[0]]: feriadoNombre,
            };

            return [...acc, Feriado];
        }, []);

        setFeriadosLocalState(FeriadosPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <Link
                                to={`/feriados_series?feriado_id=${row.row.id}`}
                            >
                                <HeaderButton
                                    text={"Ver feriados"}
                                    icon={lupa}
                                    onClick={() =>
                                        setRoute({
                                            route: ROUTES["feriados_series"],
                                        })
                                    }
                                />
                            </Link>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Feriado - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Feriado - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Feriado - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={FeriadosLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
