import { useRecoilValue } from "recoil";
import { userCredentials } from "recoil/GlobalState";

import { ROUTES } from "consts/routes";

export default function ProtectedRoute({ isSuperAuth = false, children }) {
    const credentials = useRecoilValue(userCredentials);

    // leave redirection on no credentials OFF FOR DEVELOPMENT to be able to work without complications :)
    // if (!credentials && process.env.NODE_ENV !== "development") window.location.assign(ROUTES.login.route);

    if (!isSuperAuth) return children;

    if (credentials && !credentials.isSuper) {
        window.location.replace(ROUTES.home.route);
    } else {
        return children;
    }
}
