import React, { useEffect, useState } from "react";

import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import { Button } from "components";

import { Formik, Form } from "formik";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarData, userCredentials } from "recoil/GlobalState";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/usuarios/UserFilters.module.scss";

export default function UserFilters({ handleSubmit, setFilters, filters }) {
    const [paises, setPaises] = useState([]);
    const [aplicaciones, setAplicaciones] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const getCountries = async () => {
        const bodyPais = {
            service: "MIAPaisGet",
            params: { paisConBandera: 'N' },
        };

        const paisesResponse = await RequestServiceGet(
            bodyPais,
            credentials,
            setSnackbarInfo
        );
        setPaises(paisesResponse);
    };

    const getApplications = async () => {
        const body = {
            service: "AplicacionGet",
            params: {
                listAll: 'Y'
            },
        };

        const response = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setAplicaciones(response);
    };

    useEffect(() => {
        if (Object.keys(credentials).length > 0) {
            getCountries();
            getApplications();
        }
    }, [credentials]);

    return (
        <Formik
            initialValues={{
                usuarioNroDeDocumentoX: filters.usuarioNroDeDocumentoX || "",
                usuarioApellidos: filters.usuarioApellidos || "",
                usuarioMail: filters.usuarioMail || "",
                paisId: filters.paisId || "",
                aplicacionId: filters.aplicacionId || ""
            }}
            onSubmit={(
                {
                    usuarioNroDeDocumentoX,
                    usuarioApellidos,
                    usuarioMail,
                    paisId,
                    aplicacionId
                },
                { setSubmitting }
            ) => {
                try {
                    const params = {
                        usuarioNroDeDocumentoX: usuarioNroDeDocumentoX.replace(
                            /\./g,
                            ""
                        ),
                        usuarioApellidos: usuarioApellidos,
                        usuarioMail: usuarioMail,
                        paisId: paisId,
                        aplicacionId
                    };

                    setFilters(params);
                    handleSubmit(params);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ setValues }) => (
                <Form className={styles.form} noValidate>
                    <FormikSelectInput
                        className={styles.input}
                        disabled={!paises.length}
                        fullWidth={true}
                        name="paisId"
                    >
                        <option value="">País</option>
                        {paises?.map(({ paisId, paisNombre }) => (
                            <option key={paisId} value={paisId}>
                                {paisNombre}
                            </option>
                        ))}
                    </FormikSelectInput>

                    <FormikTextInput
                        className={styles.input}
                        fullWidth={true}
                        placeholder={"Apellido"}
                        name="usuarioApellidos"
                    />

                    <FormikTextInput
                        className={styles.input}
                        fullWidth={true}
                        placeholder={"Documento"}
                        name="usuarioNroDeDocumentoX"
                    />

                    <FormikTextInput
                        className={styles.input}
                        fullWidth={true}
                        placeholder={"Email"}
                        name="usuarioMail"
                    />

                    <FormikSelectInput
                        className={styles.input}
                        disabled={!aplicaciones.length}
                        fullWidth={true}
                        name="aplicacionId"
                    >
                        <option value="">Aplicaciones</option>
                        {aplicaciones?.map(({ aplicacionId, aplicacionNombre }) => (
                            <option key={aplicacionId} value={aplicacionId}>
                                {aplicacionNombre}
                            </option>
                        ))}
                    </FormikSelectInput>

                    <Button texto={"Buscar"} />
                </Form>)}
        </Formik >
    );
}
