import React, { useState, useEffect } from "react";

import GgleMap from "components/UI/Map/GoogleMap";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarData, userCredentials } from "recoil/GlobalState";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/components/Maps/Maps.module.scss";

export default function MapDetails() {
    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [responsePolygons, setResponsePolygons] = useState([]);

    const getPolygons = async (zonaNoEnrollId = null) => {
        const body = {
            service: "ZonaNoEnrollMapGet",
            params: {
                zonaNoEnrollId: zonaNoEnrollId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const polygonsPartial = result.reduce((acc, item) => {
            const { zonaNoEnrollCoordenadasMap } = item;

            const polygons = JSON.parse(zonaNoEnrollCoordenadasMap);

            return [...acc, polygons];
        }, []);

        setResponsePolygons(polygonsPartial);
    };

    useEffect(() => {
        if (!credentials) return;
        getPolygons();
    }, []);

    return (
        <div className={styles.googleMap}>
            <GgleMap
                height={650}
                isPolygonEditable={false}
                polygonPaths={responsePolygons}
                setResponsePolygons={setResponsePolygons}
                width={800}
                zoom={11}
            />
        </div>
    );
}
