import React, { useEffect, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import { Grid } from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [provincias, setProvincias] = useState([]);
    const [provinciasIndex, setProvinciasIndex] = useState([]);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const handleProvinces = async () => {
        const bodyProvincia = {
            service: "ProvinciaGet",
            params: {
                provinciaId: null,
                paisId: null,
                provinciaCod: null,
                provinciaNombre: null,
            },
        };

        const provinciasResponse = await RequestServiceGet(
            bodyProvincia,
            credentials,
            setSnackbarInfo
        );
        setProvincias(provinciasResponse);

        // set provinces indexes for formik validation
        provinciasResponse.map((provincia) => {
            setProvinciasIndex((provinciasIndex) => [
                ...provinciasIndex,
                provincia.provinciaId,
            ]);
        });
    };

    const handleSubmit = async ({
        localidadId,
        provinciaId,
        localidadNombre,
        localidadGeoNeLatitud,
        localidadGeoNeLongitud,
        localidadGeoSoLatitud,
        localidadGeoSoLongitud,
        localidadGeoPinLatitud,
        localidadGeoPinLongitud,
        localidadVersion,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "LocalidadUpd",
            params: {
                localidadId: Number(localidadId),
                provinciaId: Number(provinciaId),
                localidadNombre: localidadNombre,
                localidadGeoNeLatitud: Number(localidadGeoNeLatitud),
                localidadGeoNeLongitud: Number(localidadGeoNeLongitud),
                localidadGeoSoLatitud: Number(localidadGeoSoLatitud),
                localidadGeoSoLongitud: Number(localidadGeoSoLongitud),
                localidadGeoPinLatitud: Number(localidadGeoPinLatitud),
                localidadGeoPinLongitud: Number(localidadGeoPinLongitud),
                localidadVersion: localidadVersion,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.UPDATE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        handleProvinces();
    }, []);

    return (
        <Formik
            initialValues={{
                localidadId: row.row.id,
                provinciaId: row.row.provinciaId,
                localidadNombre: row.row.localidadNombre,
                localidadGeoNeLatitud: row.row.localidadGeoNELatitud,
                localidadGeoNeLongitud: row.row.localidadGeoNELongitud,
                localidadGeoSoLatitud: row.row.localidadGeoSOLatitud,
                localidadGeoSoLongitud: row.row.localidadGeoSOLongitud,
                localidadGeoPinLatitud: row.row.localidadGeoPINLatitud,
                localidadGeoPinLongitud: row.row.localidadGeoPINLongitud,
                localidadVersion: row.row.localidadVersion,
            }}
            validationSchema={Yup.object({
                provinciaId: Yup.number(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(provinciasIndex, "Provincia seleccionada inválida"),
                localidadGeoNeLatitud: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                localidadGeoNeLongitud: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                localidadGeoSoLatitud: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                localidadGeoSoLongitud: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                localidadGeoPinLatitud: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                localidadGeoPinLongitud: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                {!provincias.length ? (
                    <CircularIndeterminate />
                ) : (
                    <div className={styles.column}>
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: "center" }}
                        >
                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadNombre"
                                    disabled
                                    labelText={"Nombre"}
                                />
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="provinciaId"
                                    labelText={"Provincia"}
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                    {provincias?.map(
                                        ({ provinciaId, provinciaNombre }) => (
                                            <option
                                                key={provinciaId}
                                                value={provinciaId || ""}
                                            >
                                                {provinciaNombre}
                                            </option>
                                        )
                                    )}
                                </FormikSelectInput>
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadGeoNeLatitud"
                                    labelText={"Latitud NE"}
                                    type="number"
                                />
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadGeoNeLongitud"
                                    labelText={"Longitud NE"}
                                    type="number"
                                />
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadGeoSoLatitud"
                                    labelText={"Latitud SO"}
                                    type="number"
                                />
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadGeoSoLongitud"
                                    labelText={"Longitud SO"}
                                    type="number"
                                />
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadGeoPinLatitud"
                                    labelText={"Latitud PIN"}
                                    type="number"
                                />
                            </Grid>

                            <Grid item sm={12} md={5} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="localidadGeoPinLongitud"
                                    labelText={"Longitud PIN"}
                                    type="number"
                                />
                            </Grid>
                        </Grid>

                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Enviar" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                )}
            </Form>
        </Formik>
    );
}
