import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import {
    Header,
    SnackBar,
    ProtectedRoute,
    CircularIndeterminate,
} from "components/index";

import {
    Dashboard,
    DeviceTypes,
    DomicilioTypes,
    TelefonoTypes,
    RegistroTypes,
    ImagenTypes,
    RegistroEstado,
    Aplicacion,
    Rol,
    Servicio,
    Registro,
    DocumentoTypes,
    Paises,
    Localidad,
    Devices,
    DevicesDetails,
    EstadoCivil,
    FactorBiometrico,
    SesionEvento,
    Rubros,
    ZonasDenegadas,
    Feriados,
    FeriadoSerie,
    UsuariosNoUsuarios,
    ActividadesAfip,
    CondicionesAfip,
    ImagenFormats,
    ImagenParams,
    ImagenVarias,
    Monedas,
    MonedasSeries,
    NoUsersOnBoardingManual,
    OnBoardingDetail,
    UsuariosBO,
    Usuarios,
    UserDetailsDashboard,
    Blacklist,
    ParametrosGenerales,
} from "pages/index";

import { ROUTES } from "consts/routes";

import {
    checkAuth,
    getUserCredentials,
    usuarioMinimosGet,
} from "services/mia-services";

import { useSetRecoilState } from "recoil";
import { userCredentials, userInfo } from "recoil/GlobalState";

import "styles/global.css";

export default function App() {
    const setCredentials = useSetRecoilState(userCredentials);

    const setInfo = useSetRecoilState(userInfo);

    const [loading, setLoading] = useState(true);

    const getCredentialDevelop = async () => {
        const credentials = await getUserCredentials();

        return credentials;
    };

    useEffect(() => {
        const asyncUseEffect = async () => {
            const newCredentials = checkAuth();
            //const newCredentials = await getCredentialDevelop();
            setCredentials(newCredentials);
            const data = await usuarioMinimosGet(newCredentials);
            if (data) setInfo(data);
            setLoading(false);
        };
        asyncUseEffect();
    }, []);

    if (loading) return <CircularIndeterminate />;

    return (
        <Router>
            <Header />

            <SnackBar />

            <Routes>
                {/* Default */}
                <Route path="" element={<Navigate to={ROUTES.home.route} />} />
                {/* Home */}
                <Route
                    path={ROUTES.home.route}
                    exact
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                {/* usuarios no usuarios */}
                <Route
                    path={ROUTES.no_usuarios.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.no_usuarios.needSuperAuth}
                        >
                            <UsuariosNoUsuarios />
                        </ProtectedRoute>
                    }
                />
                {/* usuarios backoffice */}
                <Route
                    path={ROUTES.usuarios_bo.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.usuarios_bo.needSuperAuth}
                        >
                            <UsuariosBO />
                        </ProtectedRoute>
                    }
                />
                {/* on boarding manual */}
                <Route
                    path={ROUTES.onboarding_manual.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.onboarding_manual.needSuperAuth}
                        >
                            <OnBoardingDetail />
                        </ProtectedRoute>
                    }
                />
                {/* device types */}
                <Route
                    path={ROUTES.device_tipos.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.device_tipos.needSuperAuth}
                        >
                            <DeviceTypes />
                        </ProtectedRoute>
                    }
                />
                {/* domicilio types */}
                <Route
                    path={ROUTES.domicilio_tipos.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.domicilio_tipos.needSuperAuth}
                        >
                            <DomicilioTypes />
                        </ProtectedRoute>
                    }
                />
                {/* telefono types */}
                <Route
                    path={ROUTES.telefono_tipos.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.telefono_tipos.needSuperAuth}
                        >
                            <TelefonoTypes />
                        </ProtectedRoute>
                    }
                />
                {/* registro types */}
                <Route
                    path={ROUTES.registro_tipos.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.registro_tipos.needSuperAuth}
                        >
                            <RegistroTypes />
                        </ProtectedRoute>
                    }
                />
                {/* imagen types */}
                <Route
                    path={ROUTES.imagen_tipo.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.imagen_tipo.needSuperAuth}
                        >
                            <ImagenTypes />
                        </ProtectedRoute>
                    }
                />
                {/* tipo documento */}
                <Route
                    path={ROUTES.documento_tipo.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.documento_tipo.needSuperAuth}
                        >
                            <DocumentoTypes />
                        </ProtectedRoute>
                    }
                />
                {/* estado de registro */}
                <Route
                    path={ROUTES.registro_estado.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.registro_estado.needSuperAuth}
                        >
                            <RegistroEstado />
                        </ProtectedRoute>
                    }
                />
                {/* aplicación */}
                <Route
                    path={ROUTES.aplicaciones.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.aplicaciones.needSuperAuth}
                        >
                            <Aplicacion />
                        </ProtectedRoute>
                    }
                />
                {/* rol */}
                <Route
                    path={ROUTES.roles.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.roles.needSuperAuth}
                        >
                            <Rol />
                        </ProtectedRoute>
                    }
                />
                {/* servicio */}
                <Route
                    path={ROUTES.servicios.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.servicios.needSuperAuth}
                        >
                            <Servicio />
                        </ProtectedRoute>
                    }
                />
                {/* registro */}
                <Route
                    path={ROUTES.registro.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.registro.needSuperAuth}
                        >
                            <Registro />
                        </ProtectedRoute>
                    }
                />
                {/* paises */}
                <Route
                    path={ROUTES.paises.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.paises.needSuperAuth}
                        >
                            <Paises />
                        </ProtectedRoute>
                    }
                />
                {/* localidad */}
                <Route
                    path={ROUTES.localidad.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.localidad.needSuperAuth}
                        >
                            <Localidad />
                        </ProtectedRoute>
                    }
                />
                {/* devices */}
                <Route
                    path={ROUTES.devices.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.devices.needSuperAuth}
                        >
                            <Devices />
                        </ProtectedRoute>
                    }
                />
                {/* devices details */}
                <Route
                    path={ROUTES.devices_details.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.devices_details.needSuperAuth}
                        >
                            <DevicesDetails />
                        </ProtectedRoute>
                    }
                />
                {/* estado civil */}
                <Route
                    path={ROUTES.estado_civil.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.estado_civil.needSuperAuth}
                        >
                            <EstadoCivil />
                        </ProtectedRoute>
                    }
                />
                {/* factor biometrico */}
                <Route
                    path={ROUTES.factor_biometrico.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.factor_biometrico.needSuperAuth}
                        >
                            <FactorBiometrico />
                        </ProtectedRoute>
                    }
                />
                {/* sesion evento */}
                <Route
                    path={ROUTES.eventos_de_sesion.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.eventos_de_sesion.needSuperAuth}
                        >
                            <SesionEvento />
                        </ProtectedRoute>
                    }
                />
                {/* rubros */}
                <Route
                    path={ROUTES.rubros.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.rubros.needSuperAuth}
                        >
                            <Rubros />
                        </ProtectedRoute>
                    }
                />
                {/* zonas denegadas */}
                <Route
                    path={ROUTES.zonas_denegadas.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.zonas_denegadas.needSuperAuth}
                        >
                            <ZonasDenegadas />
                        </ProtectedRoute>
                    }
                />
                {/* feriados */}
                <Route
                    path={ROUTES.feriados.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.feriados.needSuperAuth}
                        >
                            <Feriados />
                        </ProtectedRoute>
                    }
                />
                {/* feriados series */}
                <Route
                    path={ROUTES.feriados_series.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.feriados_series.needSuperAuth}
                        >
                            <FeriadoSerie />
                        </ProtectedRoute>
                    }
                />
                {/* actividades afip */}
                <Route
                    path={ROUTES.actividades_afip.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.actividades_afip.needSuperAuth}
                        >
                            <ActividadesAfip />
                        </ProtectedRoute>
                    }
                />
                {/* condiciones afip */}
                <Route
                    path={ROUTES.condiciones_afip.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.condiciones_afip.needSuperAuth}
                        >
                            <CondicionesAfip />
                        </ProtectedRoute>
                    }
                />
                {/* imagen formats */}
                <Route
                    path={ROUTES.imagen_formats.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.imagen_formats.needSuperAuth}
                        >
                            <ImagenFormats />
                        </ProtectedRoute>
                    }
                />
                {/* imagen formats */}
                <Route
                    path={ROUTES.imagen_params.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.imagen_params.needSuperAuth}
                        >
                            <ImagenParams />
                        </ProtectedRoute>
                    }
                />
                {/* imagen varias */}
                <Route
                    path={ROUTES.imagen_varias.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.imagen_varias.needSuperAuth}
                        >
                            <ImagenVarias />
                        </ProtectedRoute>
                    }
                />
                {/* monedas */}
                <Route
                    path={ROUTES.monedas.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.monedas.needSuperAuth}
                        >
                            <Monedas />
                        </ProtectedRoute>
                    }
                />
                {/* monedas */}
                <Route
                    path={ROUTES.monedas_series.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.monedas_series.needSuperAuth}
                        >
                            <MonedasSeries />
                        </ProtectedRoute>
                    }
                />
                {/* no usuarios detalle */}
                <Route
                    path={ROUTES.no_usuarios_detail.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={
                                ROUTES.no_usuarios_detail.needSuperAuth
                            }
                        >
                            <NoUsersOnBoardingManual />
                        </ProtectedRoute>
                    }
                />
                {/* users */}
                <Route
                    path={ROUTES.usuarios.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.usuarios.needSuperAuth}
                        >
                            <Usuarios />
                        </ProtectedRoute>
                    }
                />
                {/* users detalle */}
                <Route
                    path={ROUTES.usuarios_detail_dashboard.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={
                                ROUTES.usuarios_detail_dashboard.needSuperAuth
                            }
                        >
                            <UserDetailsDashboard />
                        </ProtectedRoute>
                    }
                />
                {/* blacklist */}
                <Route
                    path={ROUTES.blacklist.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.blacklist.needSuperAuth}
                        >
                            <Blacklist />
                        </ProtectedRoute>
                    }
                />
                {/* params_generales */}
                <Route
                    path={ROUTES.params_generales.route}
                    exact
                    element={
                        <ProtectedRoute
                            isSuperAuth={ROUTES.params_generales.needSuperAuth}
                        >
                            <ParametrosGenerales />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}
