import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { monedasSeries as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
    breadcrumbState,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";
import moment from "moment";

export default function monedas_series() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const [searchParams] = useSearchParams();

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setBreadCrumbs = useSetRecoilState(breadcrumbState);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [monedaId, setMonedaId] = useState("");
    const [monedaSeriesLocalState, setMonedaSeriesLocalState] = useState([]);

    const handleUpdateTable = async (
        monedaSelectedId = monedaId,
        fechaDesde = moment().subtract(1, "months").format("YYYY-MM-DD"),
        fechaHasta = moment().format("YYYY-MM-DD")
    ) => {
        await formatMonedaSerie(monedaSelectedId, fechaDesde, fechaHasta);
        setRow({ status: false, row: {} });
    };

    const formatMonedaSerie = async (monedaId, fechaDesde, fechaHasta) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "MonedaSerieGet",
            params: {
                monedaId: Number(monedaId),
                fechaDesde: moment(fechaDesde).format("YYYY-MM-DD"),
                fechaHasta: moment(fechaHasta).format("YYYY-MM-DD"),
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const monedaSeriesPartial = result.reduce((acc, item) => {
            const {
                monedaSerieId,
                monedaId,
                monedaCod,
                monedaNombre,
                monedaSerieFecha,
                monedaSerieQMoneda,
                monedaSerieValorComprador,
                monedaSerieValorVendedor,
                monedaSerieVersion,
            } = item;

            const monedaSerie = {
                id: monedaSerieId,
                monedaSerieId: monedaSerieId,
                monedaId: monedaId,
                monedaCod: monedaCod,
                monedaNombre: monedaNombre,
                monedaSerieFecha: monedaSerieFecha,
                monedaSerieQMoneda: monedaSerieQMoneda,
                monedaSerieValorComprador: monedaSerieValorComprador,
                monedaSerieValorVendedor: monedaSerieValorVendedor,
                monedaSerieVersion: monedaSerieVersion,
                [titles[0]]: monedaCod,
                [titles[1]]: monedaNombre,
                [titles[2]]: monedaSerieFecha,
                [titles[3]]: monedaSerieQMoneda,
                [titles[4]]: monedaSerieValorComprador,
                [titles[5]]: monedaSerieValorVendedor,
            };

            return [...acc, monedaSerie];
        }, []);

        setMonedaSeriesLocalState(monedaSeriesPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;

        const monedaId = searchParams.get("moneda_id");

        // prevent visiting page without moneda id
        if (!monedaId) {
            window.location.replace(ROUTES.monedas.route);
        }

        setMonedaId(monedaId);
        formatMonedaSerie(monedaId);
    }, [credentials]);

    useEffect(() => {
        setBreadCrumbs([
            { name: ROUTES.monedas.wordKey, route: ROUTES.monedas.route },
            {
                name: ROUTES.monedas_series.wordKey,
                route: ROUTES.monedas_series.route,
            },
        ]);

        setRow({ status: false, row: {} });
    }, []);

    useEffect(() => {
        setRoute(ROUTES.monedas_series.route);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Serie de Moneda - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Serie de Moneda - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Serie de Moneda - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={monedaSeriesLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
