import React, { useEffect, useState } from "react";

import { estadoCivil as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function EstadoCivil() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [mailTypesLocalState, setEstadoCivilLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatEstadoCivil();
        setRow({ status: false, row: {} });
    };

    const formatEstadoCivil = async (
        estadoCivilId = null,
        estadoCivilCod = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "EstadoCivilGet",
            params: {
                estadoCivilId: estadoCivilId,
                estadoCivilCod: estadoCivilCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const mailTypesPartial = result.reduce((acc, item) => {
            const {
                estadoCivilId,
                estadoCivilCod,
                estadoCivilNombre,
                estadoCivilUID,
                estadoCivilUFH,
                estadoCivilVersion,
            } = item;

            const mailType = {
                id: estadoCivilId,
                estadoCivilCod: estadoCivilCod,
                estadoCivilNombre: estadoCivilNombre,
                estadoCivilUID: estadoCivilUID,
                estadoCivilUFH: estadoCivilUFH,
                estadoCivilVersion: estadoCivilVersion,
                [titles[0]]: estadoCivilCod,
                [titles[1]]: estadoCivilNombre,
            };

            return [...acc, mailType];
        }, []);

        setEstadoCivilLocalState(mailTypesPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <Buttons>
                <HeaderButton
                    text={"Actualizar página"}
                    icon={reload}
                    updateList={handleUpdateTable}
                />
            </Buttons>
            <XGridDemo
                rows={mailTypesLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
