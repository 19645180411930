import React, { useEffect, useState } from "react";

import { params_generales as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function ParametrosGenerales() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [paramGeneralesLocalState, setParamGeneralesLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatParamGenerales();
        setRow({ status: false, row: {} });
    };

    const formatParamGenerales = async (
        paramFH = null,
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ParamGeneralGet",
            params: {
                paramAll: 'Y'
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const paramGeneralesPartial = result.map((item) => {
            const {
                paramId,
                paramBlackListMatchBO,
                paramBlackListMatchRE,
                paramDNIMatchBO,
                paramDNIMatchOK,
                paramEditable,
                paramFH,
                paramRENAPERMatchBO,
                paramRENAPERMatchOK,
                paramUFH,
                paramUID,
                paramVersion,
                paramVidaNotificaciones
            } = item;

            const paramGeneralesType = {
                id: paramId,
                paramBlacklistMatchBO: paramBlackListMatchBO,
                paramBlacklistMatchRE: paramBlackListMatchRE,
                paramDNIMatchBO: paramDNIMatchBO,
                paramDNIMatchOK: paramDNIMatchOK,
                paramEditable: paramEditable,
                paramFH: paramFH,
                paramRENAPERMatchBO: paramRENAPERMatchBO,
                paramRENAPERMatchOK: paramRENAPERMatchOK,
                paramUFH: paramUFH,
                paramUID: paramUID,
                paramVersion: paramVersion,
                paramVidaNotificaciones: paramVidaNotificaciones,
                [titles[0]]: Number(paramBlackListMatchRE),
                [titles[1]]: Number(paramBlackListMatchBO),
                [titles[2]]: Number(paramDNIMatchOK),
                [titles[3]]: Number(paramDNIMatchBO),
                [titles[4]]: Number(paramRENAPERMatchOK),
                [titles[5]]: Number(paramRENAPERMatchBO),
                [titles[6]]: paramFH,
            };

            return paramGeneralesType;
        });

        setParamGeneralesLocalState(paramGeneralesPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={row.row.paramEditable === "Y" ? "Editar" : "Edición no permitida para este parámetro"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "ParamGenerales - Editar",
                                    button: "Confirmar",
                                }}
                                disabled={row.row.paramEditable === "N" || false}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "ParamGenerales - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={paramGeneralesLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
