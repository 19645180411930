import React from "react";

import { useSetRecoilState } from "recoil";
import { modalData } from "recoil/GlobalState";

import styles from "styles/components/UI/HeaderButton.module.scss";

export default function CircularButton({
    text,
    icon,
    handleClick,
    data = { action: "noOpen" },
    deleteImage,
    setOpen,
    exportar,
    updateList,
    placeholder,
    disabled = false
}) {
    const setModalDetails = useSetRecoilState(modalData);

    return (
        <div className={`${styles.tooltip} ${styles.bottom}`}>
            <div
                className={`${styles.buttons} ${disabled ? styles.disabled : ""}`}
                onClick={() => {
                    if (disabled) {
                        return;
                    } else {
                        if (text === "Actualizar página") return updateList();
                        if (exportar) return exportar();
                        if (handleClick) handleClick(data);
                        if (deleteImage) deleteImage();
                        if (setOpen) setOpen(true);
                        if (data.action !== "noOpen")
                            setModalDetails({
                                isOpen: true,
                                action: data.action,
                                title: data.title
                            });
                    }
                }}
            >
                <img src={icon} alt={text} />
                <span className={styles.tiptext}>{text}</span>
                <span className={styles.placeholder}>{placeholder}</span>
            </div>
        </div>
    );
}
