import React, { useEffect, useState } from "react";

import { blacklist as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function Blacklist() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [blacklistLocalState, setBlacklistLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatBlacklist();
        setRow({ status: false, row: {} });
    };

    const formatBlacklist = async (
        tipoDeDocumentoId = null,
        usuarioNroDeDocumentoX = null,
        usuarioSexo = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "UsuarioBlacklistGet",
            params: {
                tipoDeDocumentoId: tipoDeDocumentoId,
                usuarioNroDeDocumentoX: usuarioNroDeDocumentoX,
                usuarioSexo: usuarioSexo
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const blacklistPartial = result.reduce((acc, item) => {
            const { tipoDeDocumentoCod,
                tipoDeDocumentoId,
                usuarioBlacklistId,
                usuarioBlacklistNroDeDocumento,
                usuarioBlacklistNroDeDocumentoX,
                usuarioBlacklistSexo,
                usuarioBlacklistOMNIId,
                usuarioBlacklistVersion
            } = item;
            console.log(item);
            const blacklist = {
                id: usuarioBlacklistId,
                tipoDeDocumentoCod: tipoDeDocumentoCod,
                tipoDeDocumentoId: tipoDeDocumentoId,
                usuarioBlacklistNroDeDocumento: usuarioBlacklistNroDeDocumento,
                usuarioBlacklistNroDeDocumentoX: usuarioBlacklistNroDeDocumentoX,
                usuarioBlacklistSexo: usuarioBlacklistSexo,
                usuarioBlacklistOMNIId: usuarioBlacklistOMNIId,
                usuarioBlacklistVersion: usuarioBlacklistVersion,
                [titles[0]]: tipoDeDocumentoCod,
                [titles[1]]: String(usuarioBlacklistNroDeDocumento),
                [titles[2]]: String(usuarioBlacklistNroDeDocumentoX),
                [titles[3]]: usuarioBlacklistOMNIId ? "Y" : "N",
                [titles[4]]: usuarioBlacklistSexo,
            };

            return [...acc, blacklist];
        }, []);

        setBlacklistLocalState(blacklistPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Blacklist - Eliminar",
                                    button: "hola",
                                }}
                            />
                            {/* <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Blacklist - Editar",
                                    button: "Confirmar",
                                }}
                            /> */}
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Blacklist - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={blacklistLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
