import React, { useEffect, useState } from "react";

import { servicio as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function Servicio() {
    const credentials = useRecoilValue(userCredentials);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [servicioLocalState, setServicioLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatServicio();
        setRow({ status: false, row: {} });
    };

    const formatServicio = async (servicioId = null, servicioCod = null) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ServicioHooliGet",
            params: {
                servicioHooliId: servicioId,
                servicioHooliCod: servicioCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const servicioPartial = result.reduce((acc, item) => {
            const {
                servicioId,
                servicioCod,
                servicioNombre,
                servicioUID,
                servicioUFH,
                servicioVersion,
            } = item;

            const servicio = {
                id: servicioId,
                servicioCod: servicioCod,
                servicioNombre: servicioNombre,
                servicioUID: servicioUID,
                servicioUFH: servicioUFH,
                servicioVersion: servicioVersion,
                [titles[0]]: servicioCod,
                [titles[1]]: servicioNombre,
            };

            return [...acc, servicio];
        }, []);

        setServicioLocalState(servicioPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <Buttons>
                <HeaderButton
                    text={"Actualizar página"}
                    icon={reload}
                    updateList={handleUpdateTable}
                />
            </Buttons>

            <XGridDemo
                rows={servicioLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
