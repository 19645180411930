import React, { useEffect, useState } from "react";

import { pais as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function Paises() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [paisLocalState, setPaisLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatPais();
        setRow({ status: false, row: {} });
    };

    const formatPais = async (paisId = null, paisCod = null) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "PaisGet",
            params: {
                paisId: paisId,
                paisCod: paisCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const paisPartial = result.reduce((acc, item) => {
            const {
                paisId,
                paisCod,
                paisNombre,
                paisEsDefault,
                paisDivisionPolitica,
                imagePathFLAG,
                paisUID,
                paisUFH,
                paisVersion,
            } = item;

            const pais = {
                id: paisId,
                paisCod: paisCod,
                paisNombre: paisNombre,
                paisEsDefault: paisEsDefault,
                paisDivisionPolitica: paisDivisionPolitica,
                imagePathFLAG: imagePathFLAG,
                paisUID: paisUID,
                paisUFH: paisUFH,
                paisVersion: paisVersion,
                [titles[0]]: paisCod,
                [titles[1]]: paisNombre,
                [titles[2]]: paisEsDefault === "Y" ? "Sí" : "No",
                [titles[3]]: paisDivisionPolitica
                    ? paisDivisionPolitica
                    : "N/A",
                [titles[4]]: imagePathFLAG,
            };

            return [...acc, pais];
        }, []);

        setPaisLocalState(paisPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={action === ACTIONS.UPDATE ?? true}
            >
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Pais - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={paisLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
