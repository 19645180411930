import React from "react";

import editar from "assets/icons/editar.svg";
import modalEditar from "assets/icons/modal-editar.svg";

import {
    CheckCircleOutlineOutlined as CheckIcon,
    CancelOutlined as CancelIcon,
} from "@material-ui/icons";

import CircularIndeterminate from "components/UI/Spinner/Spinner";
import { Button } from "components";

import styles from "styles/pages/usuariosBO/EndUserModal.module.scss";
import { ESTADO_REGISTRO_COD } from "consts/estadoRegistroCod";

export default function EndUserModal({ isLoading, openEdit, closeModal, userStatus }) {
    const codsRegistros = [
        "EU0002",
        "EU0003",
        "EU0004",
        "EU0005",
        "EU0006",
        "EU0015"
    ];

    return (
        <div className={styles.container}>
            {isLoading ? (
                <CircularIndeterminate />
            ) : (
                userStatus
                    .filter((status) => status.estadoDeRegistroCod !== "F")
                    .map((status) => {
                        return (
                            <div
                                key={`${status.registroCod} ${status.registroId}`}
                                className={styles.result}
                            >
                                <div className={styles.status}>
                                    <div className={styles.icon}>
                                        {status.estadoDeRegistroCod === ESTADO_REGISTRO_COD.aprobado && (
                                            <CheckIcon
                                                fontSize={"large"}
                                                className={styles.checkIcon}
                                            />
                                        )}

                                        {status.estadoDeRegistroCod === ESTADO_REGISTRO_COD.en_espera && (
                                            <img
                                                src={modalEditar}
                                                className={styles.updateIcon}
                                            />
                                        )}

                                        {status.estadoDeRegistroCod === ESTADO_REGISTRO_COD.rechazado && (
                                            <CancelIcon
                                                fontSize={"large"}
                                                className={styles.cancelIcon}
                                            />
                                        )}
                                    </div>

                                    <p className={styles.registro}>
                                        {status.registroNombre}
                                    </p>

                                    {codsRegistros.includes(status.registroCod) && (
                                        <img
                                            className={styles.editIcon}
                                            src={editar}
                                            onClick={() =>
                                                openEdit(status.registroCod)
                                            }
                                        />
                                    )}
                                </div>
                                <hr />
                            </div>
                        );
                    })
            )}
            <div className={styles.closeButtonContainer}>
                <Button
                    onClick={closeModal}
                    texto={"Cerrar"}
                />
            </div>
        </div>
    );
}
