import React, { useEffect, useState } from "react";

import { documentoTypes as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function DocumentoTypes() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [tipoDeDocumentoLocalState, setDocumentoTipoLocalState] = useState(
        []
    );

    const handleUpdateTable = () => {
        formatDocumentoTipo();
        setRow({ status: false, row: {} });
    };

    const formatDocumentoTipo = async (
        tipoDeDocumentoId = null,
        tipoDeDocumentoCod = null,
        paisId = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "TipoDeDocumentoGet",
            params: {
                tipoDeDocumentoId: tipoDeDocumentoId,
                tipoDeDocumentoCod: tipoDeDocumentoCod,
                paisId: paisId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const tipoDeDocumentoPartial = result.reduce((acc, item) => {
            const {
                tipoDeDocumentoId,
                tipoDeDocumentoPlaceholder,
                tipoDeDocumentoCod,
                tipoDeDocumentoCodMB,
                tipoDeDocumentoNombre,
                tipoDeDocumentoAmbito,
                paisId,
                paisCod,
                paisNombre,
                paisEsArgentina,
                provinciaId,
                provinciaCod,
                provinciaNombre,
                tipoDeDocumentoRegExp,
                tipoDeDocumentoUID,
                tipoDeDocumentoUFH,
                tipoDeDocumentoVersion,
            } = item;

            const documentoType = {
                id: tipoDeDocumentoId,
                tipoDeDocumentoPlaceholder: tipoDeDocumentoPlaceholder,
                tipoDeDocumentoCod: tipoDeDocumentoCod,
                tipoDeDocumentoCodMB: tipoDeDocumentoCodMB,
                tipoDeDocumentoNombre: tipoDeDocumentoNombre,
                tipoDeDocumentoAmbito: tipoDeDocumentoAmbito,
                paisId: paisId,
                paisCod: paisCod,
                paisNombre: paisNombre,
                paisEsArgentina: paisEsArgentina,
                provinciaId: provinciaId,
                provinciaCod: provinciaCod,
                provinciaNombre: provinciaNombre,
                tipoDeDocumentoRegExp: tipoDeDocumentoRegExp,
                tipoDeDocumentoUID: tipoDeDocumentoUID,
                tipoDeDocumentoUFH: tipoDeDocumentoUFH,
                tipoDeDocumentoVersion: tipoDeDocumentoVersion,
                [titles[0]]: tipoDeDocumentoCod,
                [titles[1]]: tipoDeDocumentoNombre,
                [titles[2]]: tipoDeDocumentoCodMB
                    ? tipoDeDocumentoCodMB
                    : "N/A",
                [titles[3]]: tipoDeDocumentoAmbito
                    ? tipoDeDocumentoAmbito
                    : "N/A",
                [titles[4]]: paisNombre,
                [titles[5]]: provinciaNombre ? provinciaNombre : "N/A",
            };

            return [...acc, documentoType];
        }, []);

        setDocumentoTipoLocalState(tipoDeDocumentoPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Documento Tipo - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Documento Tipo - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Documento Tipo - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={tipoDeDocumentoLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
