import React from "react";
// styles
import styles from "styles/components/UI/Buttons.module.scss";

export default function Buttons({ children, filtersApplied = null }) {
    return (
        <div className={styles.buttonsContainer}>
            <div
                className={
                    filtersApplied ? styles.icons : styles.iconsNoFilters
                }
            >
                {children}
            </div>
        </div>
    );
}
