import React, { useEffect, useState } from "react";

import { condicionAFIP as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function CondicionesAfip() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [condicionAFIPLocalState, setCondicionAFIPLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatCondicionAFIP();
        setRow({ status: false, row: {} });
    };

    const formatCondicionAFIP = async (
        condicionAFIPId = null,
        condicionAFIPCod = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "CondicionAFIPGet",
            params: {
                condicionAFIPId: condicionAFIPId,
                condicionAFIPCod: condicionAFIPCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const condicionAFIPPartial = result.reduce((acc, item) => {
            const {
                condicionAFIPId,
                condicionAFIPCod,
                condicionAFIPNombre,
                condicionAFIPUID,
                condicionAFIPUFH,
                condicionAFIPVersion,
            } = item;

            const condicionAFIPType = {
                id: condicionAFIPId,
                condicionAFIPCod: condicionAFIPCod,
                condicionAFIPNombre: condicionAFIPNombre,
                condicionAFIPUID: condicionAFIPUID,
                condicionAFIPUFH: condicionAFIPUFH,
                condicionAFIPVersion: condicionAFIPVersion,
                [titles[0]]: condicionAFIPCod,
                [titles[1]]: condicionAFIPNombre,
                [titles[2]]: condicionAFIPNombre,
            };

            return [...acc, condicionAFIPType];
        }, []);

        setCondicionAFIPLocalState(condicionAFIPPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <Buttons>
                <>
                    <>
                        <HeaderButton
                            text={"Actualizar página"}
                            icon={reload}
                            updateList={handleUpdateTable}
                        />
                    </>
                </>
            </Buttons>

            <XGridDemo
                rows={condicionAFIPLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
