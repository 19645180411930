import React, { useState, useEffect } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import { Grid } from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [aplicaciones, setAplicaciones] = useState([]);
    const [clientes, setClientes] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const getDeviceTypes = async () => {
        const body = {
            service: "TipoDeDeviceGet",
            params: {},
        };

        const deviceTypesResponse = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setDeviceTypes(deviceTypesResponse);
    };
    const getServices = async () => {
        const body = {
            service: "ServicioHooliGet",
            params: {},
        };

        const servicesResponse = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setServicios(servicesResponse);
    };
    const getClientes = async () => {
        const body = {
            service: "MerchantGet",
            params: {},
        };

        const response = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setClientes(response);
    };
    const getAplicaciones = async () => {
        const body = {
            service: "AplicacionGet",
            params: {
                listAll: 'Y'
            },
        };

        const response = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setAplicaciones(response);
    };

    const handleSubmit = async ({
        tipoDeviceId,
        deviceMarca,
        deviceModelo,
        deviceSerie,
        deviceIdentificacion,
        deviceFechaCompra,
        deviceMAC,
        clienteId,
        servicioId,
        aplicacionId,
        deviceESTipo,
        gateDeviceLicencia,
        gateDeviceUmbral,
        gateDeviceDelta,
        gateDeviceTiempoAccion,
        gateDeviceRequiereEntrada,
        gateDeviceRequiereSalida
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "DeviceIns",
            params: {
                merchantId: clienteId,
                servicioId,
                aplicacionId,
                tipoDeviceId,
                deviceMarca,
                deviceModelo,
                deviceSerie,
                deviceIdentificacion,
                deviceFechaCompra,
                deviceMAC,
                deviceESTipo,
                gateDeviceLicencia,
                gateDeviceUmbral,
                gateDeviceDelta,
                gateDeviceTiempoAccion,
                gateDeviceRequiereEntrada,
                gateDeviceRequiereSalida
            },
        };
        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );

        handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        Promise.all([
            getDeviceTypes(),
            getServices(),
            getAplicaciones(),
            getClientes()
        ]);
    }, []);

    return (
        <Formik
            initialValues={{
                clienteId: "",
                servicioId: "",
                aplicacionId: "",
                tipoDeviceId: "",
                deviceMarca: "",
                deviceModelo: "",
                deviceSerie: "",
                deviceIdentificacion: "",
                deviceFechaCompra: new Date().toISOString().substring(0, 10),
                deviceMAC: "",
                deviceStatus: "A",
                deviceESTipo: "",
                gateDeviceLicencia: "",
                gateDeviceUmbral: 0,
                gateDeviceDelta: 0,
                gateDeviceTiempoAccion: "",
                gateDeviceRequiereEntrada: "N",
                gateDeviceRequiereSalida: "N"
            }}
            validationSchema={Yup.object({
                tipoDeviceId: Yup.number(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required),
                deviceMarca: Yup.string(FORM_ERROR_MESSAGES.text).required(
                    FORM_ERROR_MESSAGES.required
                ),
                deviceModelo: Yup.string(FORM_ERROR_MESSAGES.text).required(
                    FORM_ERROR_MESSAGES.required
                ),
                deviceSerie: Yup.string(FORM_ERROR_MESSAGES.text).required(
                    FORM_ERROR_MESSAGES.required
                ),
                deviceIdentificacion: Yup.string(
                    FORM_ERROR_MESSAGES.text
                ).required(FORM_ERROR_MESSAGES.required),
                deviceFechaCompra: Yup.date(FORM_ERROR_MESSAGES.date).required(
                    FORM_ERROR_MESSAGES.required
                ),
                deviceMAC: Yup.string(FORM_ERROR_MESSAGES.text).required(
                    FORM_ERROR_MESSAGES.required
                )
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {props => <Form className={styles.inputs} noValidate>
                <Grid container spacing={2} sx={{ justifyContent: "center" }}>
                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikSelectInput
                            fullWidth={true}
                            name="clienteId"
                            labelText={"Cliente"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {clientes?.map(
                                ({ merchantId, merchantNombre }) => (
                                    <option
                                        key={merchantId}
                                        value={merchantId || ""}
                                    >
                                        {merchantNombre}
                                    </option>
                                )
                            )}
                        </FormikSelectInput>
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="servicioId"
                            labelText={"Servicio"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {servicios?.map(
                                ({ servicioId, servicioNombre }) => (
                                    <option
                                        key={servicioId}
                                        value={servicioId || ""}
                                    >
                                        {servicioNombre}
                                    </option>
                                )
                            )}
                        </FormikSelectInput>
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="aplicacionId"
                            labelText={"Aplicación"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {aplicaciones?.map(
                                ({ aplicacionId, aplicacionNombre }) => (
                                    <option
                                        key={aplicacionId}
                                        value={aplicacionId || ""}
                                    >
                                        {aplicacionNombre}
                                    </option>
                                )
                            )}
                        </FormikSelectInput>
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="tipoDeviceId"
                            labelText={"Tipo de device"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {deviceTypes?.map(
                                ({ tipoDeDeviceId, tipoDeDeviceNombre }) => (
                                    <option
                                        key={tipoDeDeviceId}
                                        value={tipoDeDeviceId || ""}
                                    >
                                        {tipoDeDeviceNombre}
                                    </option>
                                )
                            )}
                        </FormikSelectInput>
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikTextInput
                            fullWidth={true}
                            name="deviceMarca"
                            labelText={"Marca"}
                        />
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikTextInput
                            fullWidth={true}
                            name="deviceModelo"
                            labelText={"Modelo"}
                        />
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikTextInput
                            fullWidth={true}
                            name="deviceSerie"
                            labelText={"Serie"}
                        />
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikTextInput
                            fullWidth={true}
                            name="deviceIdentificacion"
                            labelText={"Identificación"}
                        />
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikTextInput
                            fullWidth={true}
                            name="deviceFechaCompra"
                            labelText={"Fecha de compra"}
                            type="date"
                        />
                    </Grid>

                    <Grid item sm={12} md={4} textAlign="center">
                        <FormikTextInput
                            fullWidth={true}
                            name="deviceMAC"
                            labelText={"MAC"}
                        />
                    </Grid>
                    {props.values.clienteId && parseInt(props.values.servicioId) === servicios.find(s => s.servicioCod === 'HGATE')?.servicioId &&
                        <>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="deviceESTipo"
                                    labelText={"Tipo"}
                                >
                                    <option value="A">Entrada / Salida</option>
                                    <option value="E">Entrada</option>
                                    <option value="S">Salida</option>
                                </FormikSelectInput>
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="gateDeviceLicencia"
                                    labelText={"Licencia"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="gateDeviceUmbral"
                                    labelText={"Umbral"}
                                    type="number"
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="gateDeviceDelta"
                                    labelText={"Delta"}
                                    type="number"
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="gateDeviceTiempoAccion"
                                    labelText={"Tiempo de acción"}
                                    type="number"
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="gateDeviceRequiereEntrada"
                                    labelText={"Requiere entrada"}
                                >
                                    <option value="Y">Sí</option>
                                    <option value="N">No</option>
                                </FormikSelectInput>
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="gateDeviceRequiereSalida"
                                    labelText={"Requiere salida"}
                                >
                                    <option value="Y">Sí</option>
                                    <option value="N">No</option>
                                </FormikSelectInput>
                            </Grid>
                        </>
                    }
                </Grid>

                <FormButton
                    inRequest={inRequest}
                    newFormData={{ button: "Enviar" }}
                    hasConfirmationModal={false}
                />
                {isLoading && <CircularIndeterminate />}
            </Form>
            }
        </Formik>
    );
}
