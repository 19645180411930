import imageCompression from "browser-image-compression";

export const useImages = (setImages, images) => {
    const readerHandler = async (index, file) => {
        try {
            const compressedImage = await imageCompression.getDataUrlFromFile(
                file
            );

            const newImages = { ...images };

            newImages[index] = compressedImage;

            return setImages(newImages);
        } catch (error) {
            console.log(error);
        }
    };

    const handleImg = (e, index) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = async () => await readerHandler(index, file);
            reader.readAsBinaryString(file);
        }
    };

    const handleRotate = (ref, secondRef) => {
        const width = ref.current.offsetWidth;
        const height = ref.current.offsetHeight;

        let transform;

        switch (ref.current.style.transform) {
            case "":
                transform = "rotate(90deg)";
                break;
            case "rotate(90deg)":
                transform = "rotate(180deg)";
                break;
            case "rotate(180deg)":
                transform = "rotate(270deg)";
                break;
            case "rotate(270deg)":
                transform = "";
                break;
            default:
                transform = "";
        }

        if (secondRef && secondRef.current)
            secondRef.current.style.transform = transform;

        ref.current.style.transform = transform;
        ref.current.style.width = height;
        ref.current.style.height = width;
    };

    async function downloadIMG(imgUrls) {
        /* Loading images from a remote server and converting them to data URLs. */
        for (const imgUrl in imgUrls) {
            if (!imgUrl) return;

            const image = new Image();

            image.crossOrigin = "anonymous";

            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.height = image.naturalHeight;
                canvas.width = image.naturalWidth;

                ctx.drawImage(image, 0, 0);

                const dataUrl = canvas.toDataURL('image/jpeg');

                setImages((images) => ({ ...images, [imgUrl]: dataUrl }));
            };

            image.src = imgUrls[imgUrl];
        }
    }

    return { downloadIMG, handleImg, handleRotate };
};
