import React, { useEffect } from "react";
import { Fade, Modal, Typography } from "@mui/material";

import close from "assets/icons/borrar.svg";

import { useRecoilState } from "recoil";
import { modalData } from "recoil/GlobalState";

import FormButton from "components/UI/Button/FormButton";

import { ModalStyles } from "styles/components/UI/Modal.styles";
import { secondaryModalData } from "recoil/GlobalState";

export default function SecondaryModal({
    children,
    secondaryModalContent,
    confirmationModal = false,
}) {
    const styles = ModalStyles();
    const [secondaryModalDetails, setSecondaryModalDetails] =
        useRecoilState(secondaryModalData);

    useEffect(() => {
        const handleKeyUp = ({ key }) => {
            if (key === "Escape") {
                setSecondaryModalDetails({
                    ...secondaryModalDetails,
                    confirmationModalOpen: true,
                });
            }
        };

        secondaryModalDetails.isOpen &&
            window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [secondaryModalDetails.isOpen]);

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
                open={secondaryModalDetails.isOpen}
            >
                <Fade
                    in={secondaryModalDetails.isOpen}
                    className={styles.modal}
                >
                    <div className={styles.modal}>
                        <div className={styles.paper}>
                            <img
                                src={close}
                                className={styles.closeButton}
                                role="button"
                                onClick={() => {
                                    confirmationModal
                                        ? setSecondaryModalDetails({
                                              ...secondaryModalDetails,
                                              confirmationModalOpen: true,
                                          })
                                        : setSecondaryModalDetails({
                                              isOpen: false,
                                          });
                                }}
                            />
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>

            {confirmationModal && secondaryModalDetails.confirmationModalOpen && (
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={styles.confirmationModal}
                    open={secondaryModalDetails.isOpen}
                >
                    <form
                        onSubmit={() =>
                            setSecondaryModalDetails({
                                isOpen: false,
                                confirmationModalOpen: false,
                            })
                        }
                    >
                        <Fade
                            in={secondaryModalDetails.isOpen}
                            className={styles.modal}
                        >
                            <div className={styles.confirmationModal}>
                                <div className={styles.paper}>
                                    ¿Estás seguro que deseás cancelar?
                                    <br />
                                    Perderás todos los cambios establecidos
                                    <FormButton
                                        closeButtonText={"No"}
                                        isConfirmationModal={true}
                                        newFormData={{ button: "Sí" }}
                                    />
                                </div>
                            </div>
                        </Fade>
                    </form>
                </Modal>
            )}
        </>
    );
}
