import React, { useState, useRef, useEffect } from "react";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { useRecoilState, useSetRecoilState } from "recoil";
import { modalData, snackbarData } from "recoil/GlobalState";

import { Form, Formik } from "formik";
import { FormButton } from "components";

import styles from "styles/pages/usuariosNoUsuarios/PreonboardingManual.module.scss";

export default function ModalCropImage({
    image,
    imageRef,
    setRecorte,
    images,
    setImages,
}) {
    const [cutImage, setCutImage] = useState();
    const [inRequest, setInRequest] = useState(false);

    const cropperRef = useRef(null);
    const croppedImageRef = useRef(null);

    const [modalDetails, setModalDetails] = useRecoilState(modalData);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;

        setCutImage(
            cropper
                .getCroppedCanvas({
                    height: 250,
                    imageSmoothingEnabled: true,
                    imageSmoothingQuality: "low",
                })
                .toDataURL('image/jpeg')
        );
    };

    const handleCrop = () => {
        setInRequest((inRequest) => !inRequest);

        setRecorte(cutImage);
        setImages({ ...images, recorte: cutImage });
        setModalDetails({
            isOpen: false,
        });

        setInRequest((inRequest) => !inRequest);
    };

    useEffect(() => {
        cropperRef.current.style.transform = imageRef.current.style.transform;

        if (croppedImageRef && croppedImageRef.current)
            croppedImageRef.current.style.transform =
                imageRef.current.style.transform;
    }, [imageRef.current.style, cutImage]);

    useEffect(() => {
        setModalDetails({
            ...modalDetails,
            title: "Usuarios no usuarios - Recortar imagen",
        });
    }, []);

    return (
        <Formik
            initialValues={{}}
            onSubmit={() => {
                try {
                    handleCrop();
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                }
            }}
        >
            <Form>
                <div className={styles.modalRecorte}>
                    <div className={styles.cropperContainer}>
                        <Cropper
                            src={image}
                            style={{ height: 400, width: "100%" }}
                            guides={false}
                            crop={onCrop}
                            ref={cropperRef}
                            className={styles.cropper}
                        />
                        {cutImage && (
                            <img
                                src={cutImage}
                                className={styles.cropper}
                                ref={croppedImageRef}
                            />
                        )}
                    </div>

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Recortar" }}
                    />
                </div>
            </Form>
        </Formik>
    );
}
