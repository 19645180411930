import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { Form, Formik } from "formik";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function DeleteModal({ handleUpdateTable }) {
    const [isLoading, setIsLoading] = useState(false);
    const [inRequest, setInRequest] = useState(false);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const setModalDetails = useSetRecoilState(modalData);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const handleSubmit = async ({ tipoDeImagenId }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "TipoDeImagenDel",
            params: {
                tipoDeImagenId: tipoDeImagenId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.DELETE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                tipoDeImagenId: row.row.id,
            }}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    console.error(e);
                    return e;
                    // return error modal
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <label className={styles.label}>
                        ¿Está seguro que desea eliminar este Tipo de imagen?
                    </label>

                    <FormikTextInput
                        disabled={true}
                        fullWidth={false}
                        id="tipoDeImagenNombre"
                        name="tipoDeImagenNombre"
                        type="text"
                        value={row.row.tipoDeImagenNombre}
                    />

                    <input
                        name="tipoDeImagenId"
                        id="tipoDeImagenId"
                        type="hidden"
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Confirmar" }}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
