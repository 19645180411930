import React, { useEffect } from "react";
import { Fade, Modal, Typography } from "@mui/material";

import close from "assets/icons/borrar.svg";

import { useRecoilState } from "recoil";
import { modalData } from "recoil/GlobalState";

import FormButton from "components/UI/Button/FormButton";

import { ModalStyles } from "styles/components/UI/Modal.styles";
import { ACTIONS } from "consts/actions";

export default function TransitionModal({
    children,
    title,
    confirmationModal = false,
    customOnClose = () => { },
}) {
    const [modalState, setModalDetails] = useRecoilState(modalData);
    const styles = ModalStyles();

    useEffect(() => {
        const handleKeyUp = ({ key }) => {
            if (key === "Escape") {
                setModalDetails({ ...modalState, confirmationModalOpen: true });
            }
        };

        modalState.isOpen && window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [modalState.isOpen]);

    const handleCloseModal = () => {
        if (confirmationModal) {
            setModalDetails({
                ...modalState,
                confirmationModalOpen: true,
            });
            customOnClose();
            return;
        }

        setModalDetails({ isOpen: false });
        customOnClose(null);
    };

    return (
        <>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
                open={modalState.isOpen}
            >
                <Fade in={modalState.isOpen} className={styles.modal}>
                    <div className={styles.modal}>
                        <div className={styles.paper}>
                            <Typography
                                variant="h4"
                                fontWeight={100}
                                className={styles.title}
                                style={{
                                    color: modalState.title && modalState.title.includes('Eliminar') ? "#f00" : "",
                                }}
                            >
                                {modalState ? modalState.title : title}
                            </Typography>
                            <img
                                src={close}
                                className={styles.closeButton}
                                role="button"
                                onClick={handleCloseModal}
                            />
                            {children}
                        </div>
                    </div>
                </Fade>
            </Modal>

            {confirmationModal && modalState.confirmationModalOpen && (
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={styles.confirmationModal}
                    open={modalState.isOpen}
                >
                    <form
                        onSubmit={() =>
                            setModalDetails({
                                isOpen: false,
                                confirmationModalOpen: false,
                            })
                        }
                    >
                        <Fade in={modalState.isOpen} className={styles.modal}>
                            <div className={styles.confirmationModal}>
                                <div className={styles.paper}>
                                    ¿Estás seguro que deseás cancelar?
                                    <br />
                                    Perderás todos los cambios establecidos
                                    <FormButton
                                        closeButtonText={"No"}
                                        isConfirmationModal={true}
                                        newFormData={{ button: "Sí" }}
                                    />
                                </div>
                            </div>
                        </Fade>
                    </form>
                </Modal>
            )}
        </>
    );
}
