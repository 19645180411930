import React, { useEffect, useState } from "react";

import { domicilioTypes as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function DomicilioTypes() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [domicilioTypesLocalState, setDomicilioTypesLocalState] = useState(
        []
    );

    const handleUpdateTable = () => {
        formatDomicilioTypes();
        setRow({ status: false, row: {} });
    };

    const formatDomicilioTypes = async (
        tipoDeDomicilioId = null,
        tipoDeDomicilioCod = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "TipoDeDomicilioGet",
            params: {
                tipoDeDomicilioId: tipoDeDomicilioId,
                tipoDeDomicilioCod: tipoDeDomicilioCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const domicilioTypesPartial = result.reduce((acc, item) => {
            const {
                tipoDeDomicilioId,
                tipoDeDomicilioCod,
                tipoDeDomicilioNombre,
                tipoDeDomicilioUID,
                tipoDeDomicilioUFH,
                tipoDeDomicilioVersion,
            } = item;

            const domicilioType = {
                id: tipoDeDomicilioId,
                tipoDeDomicilioCod: tipoDeDomicilioCod,
                tipoDeDomicilioNombre: tipoDeDomicilioNombre,
                tipoDeDomicilioUID: tipoDeDomicilioUID,
                tipoDeDomicilioUFH: tipoDeDomicilioUFH,
                tipoDeDomicilioVersion: tipoDeDomicilioVersion,
                [titles[0]]: tipoDeDomicilioCod,
                [titles[1]]: tipoDeDomicilioNombre,
            };

            return [...acc, domicilioType];
        }, []);

        setDomicilioTypesLocalState(domicilioTypesPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Tipos de domicilio - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Tipos de domicilio - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Tipos de domicilio - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={domicilioTypesLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
