import React, { useEffect, useState } from "react";
import moment from "moment";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import { Grid } from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [generalParams, setGeneralParams] = useState({});

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const getGeneralParams = async () => {
        setIsLoading(true);

        const body = {
            service: "ParamGeneralGet",
            params: {},
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        const params = result[0];
        params.paramFH = moment().format("YYYY-MM-DD");
        setGeneralParams(result[0]);

        setIsLoading(false);
    };

    const handleSubmit = async ({
        paramBlackListMatchBO,
        paramBlackListMatchRE,
        paramDNIMatchBO,
        paramDNIMatchOK,
        paramRENAPERMatchBO,
        paramRENAPERMatchOK,
        paramVidaNotificaciones,
        paramFH }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
        const body = {
            service: "ParamGeneralIns",
            params: {
                paramBlacklistMatchBO: Number(paramBlackListMatchBO),
                paramBlacklistMatchRE: Number(paramBlackListMatchRE),
                paramDNIMatchBO: Number(paramDNIMatchBO),
                paramDNIMatchOK: Number(paramDNIMatchOK),
                paramRENAPERMatchBO: Number(paramRENAPERMatchBO),
                paramRENAPERMatchOK: Number(paramRENAPERMatchOK),
                paramVidaNotificaciones: Number(paramVidaNotificaciones),
                paramFH: paramFH
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        credentials.length ?? getGeneralParams();
    }, [credentials]);

    return (
        <Formik
            initialValues={generalParams}
            enableReinitialize={true}
            validationSchema={Yup.object({
                paramBlackListMatchRE: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required),
                paramBlackListMatchBO: Yup.number(FORM_ERROR_MESSAGES.number).lessThan(Yup.ref("paramBlackListMatchRE"), "Debe ser menor o igual a Match Blacklist Rechazado").max(Yup.ref("paramBlackListMatchRE"), "Debe ser menor o igual a Match Blacklist Rechazado").required(FORM_ERROR_MESSAGES.required),
                paramDNIMatchBO: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required),
                paramDNIMatchOK: Yup.number(FORM_ERROR_MESSAGES.number).moreThan(Yup.ref("paramDNIMatchBO"), "Debe ser mayor o igual a Match DNI BO").min(Yup.ref("paramDNIMatchBO"), "Debe ser mayor o igual a Match DNI BO").required(FORM_ERROR_MESSAGES.required),
                paramRENAPERMatchBO: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required),
                paramRENAPERMatchOK: Yup.number(FORM_ERROR_MESSAGES.number).moreThan(Yup.ref("paramRENAPERMatchBO"), "Debe ser mayor o igual a Match Rechazado BO").min(Yup.ref("paramRENAPERMatchBO"), "Debe ser mayor o igual a Match Rechazado BO").required(FORM_ERROR_MESSAGES.required),
                paramVidaNotificaciones: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required),
                paramFH: Yup.date(FORM_ERROR_MESSAGES.date)
                    .required(FORM_ERROR_MESSAGES.required),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <Grid container columns={12} gap={3} justifyContent={"center"} width={"100%"}>
                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramBlackListMatchRE"
                                labelText={"Blacklist: Match para Rechazado"}
                                defaultValue={generalParams.paramBlackListMatchRE}
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramBlackListMatchBO"
                                labelText={"Blacklist: Match para BackOffice"}
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramDNIMatchOK"
                                labelText={"DNI: Match para OK"}
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramDNIMatchBO"
                                labelText={"DNI: Match para BackOffice"}
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramRENAPERMatchOK"
                                labelText={"RENAPER: Match para OK"}
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramRENAPERMatchBO"
                                labelText={"RENAPER: Match para BackOffice"}
                            />
                        </Grid>

                        <Grid item sm={5}>
                            <FormikTextInput
                                fullWidth={true}
                                name="paramFH"
                                labelText={"Fecha de implementación"}
                                type={"date"}
                                InputProps={{ inputProps: { min: moment().format('YYYY-MM-DD') } }}
                            />
                        </Grid>
                    </Grid>

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Crear" }}
                        hasConfirmationModal={true}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
