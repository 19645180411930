import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function UpdateModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const handleSubmit = async ({
        aplicacionId,
        aplicacionCod,
        aplicacionNombre,
        aplicacionVersion,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "AplicacionUpd",
            params: {
                aplicacionId: aplicacionId,
                aplicacionCod: aplicacionCod,
                aplicacionNombre: aplicacionNombre,
                aplicacionVersion: aplicacionVersion,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.UPDATE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                aplicacionId: row.row.id,
                aplicacionCod: row.row.aplicacionCod,
                aplicacionNombre: row.row.aplicacionNombre,
                aplicacionVersion: row.row.aplicacionVersion,
            }}
            validationSchema={Yup.object({
                aplicacionNombre: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(45, "Máximo 45 caracteres"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <FormikTextInput
                        fullWidth={true}
                        name="aplicacionNombre"
                        labelText={"Nombre"}
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Enviar" }}
                        hasConfirmationModal={true}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
