import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const handleSubmit = async ({
        actividadAfipCod,
        actividadAfipDescripcion,
        actividadAfipDetalle,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ActividadAFIPIns",
            params: {
                actividadAfipCod: String(actividadAfipCod).toUpperCase(),
                actividadAfipDescripcion: actividadAfipDescripcion,
                actividadAfipDetalle: actividadAfipDetalle,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                actividadAfipCod: "",
                actividadAfipDescripcion: "",
                actividadAfipDetalle: "",
            }}
            validationSchema={Yup.object({
                actividadAfipCod: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(6, "Máximo 6 caracteres"),
                actividadAfipDescripcion: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(300, "Máximo 300 caracteres"),
                actividadAfipDetalle: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(300, "Máximo 300 caracteres"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <FormikTextInput
                        fullWidth={true}
                        labelText={"Código"}
                        name="actividadAfipCod"
                        type="number"
                    />

                    <FormikTextInput
                        fullWidth={true}
                        labelText={"Descripción"}
                        name="actividadAfipDescripcion"
                    />

                    <FormikTextInput
                        fullWidth={true}
                        labelText={"Detalle"}
                        name="actividadAfipDetalle"
                    />

                    <FormButton
                        hasConfirmationModal={true}
                        inRequest={inRequest}
                        newFormData={{ button: "Enviar" }}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
