export const countryList = {
    ARG: {
        cod: "ARG",
        country_name: "Argentina",
    },
    COL: {
        cod: "COL",
        country_name: "Colombia",
    },
    PAN: {
        cod: "PAN",
        country_name: "Panamá",
    },
};
