import React, { useState, useEffect, useRef } from "react";

import { ErrorMessage, Form, Formik } from "formik";
import { Checkbox, FormControlLabel, FormGroup, Grid, Slider } from "@mui/material";

import * as Yup from "yup";
import moment from "moment";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { onBoardingManual } from "services/mia-services/onBoarding/onBoardingManual";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ROUTES } from "consts/routes";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { userCredentials, snackbarData, modalData } from "recoil/GlobalState";

import { FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import CircularIndeterminate from "components/UI/Spinner/Spinner";

import styles from "styles/pages/usuariosNoUsuarios/PreonboardingManualForm.module.scss";
import ZoomableImage from "components/ZoomableImage";

export default function OnBoardingManualFormColombia({ params }) {
    const [isLoading, setIsLoading] = useState(false);
    const [provincias, setProvincias] = useState([]);
    const [match, setMatch] = useState(80);
    const [isExpirationDateNoRequired, setIsExpirationDateNoRequired] = useState(false);
    const [isAddressNoRequired, setIsAddressNoRequired] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        first: false,
        second: false,
        third: false,
        fourth: false,
    });

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);
    const credentials = useRecoilValue(userCredentials);

    const navigate = useNavigate();
    const location = useLocation();

    const imagenSelfie = useRef(null);
    const imagenFrenteDNI = useRef(null);
    const imagenDorsoDNI = useRef(null);
    const imagenRecorte = useRef(null);

    const { aplicacionCod, userInfo, paisSelected, refs, images } = params;

    const { selfie, dniDorso, dniFrente, recorte } = images;

    const { dorsoRef, selfieRef, frenteRef, recorteRef } = refs;

    const getProvinces = async () => {
        const bodyProvincia = {
            service: "ProvinciaGet",
            params: {
                provinciaId: null,
                paisId: paisSelected,
                provinciaCod: null,
                provinciaNombre: null,
            },
        };

        const provinciasResponse = await RequestServiceGet(
            bodyProvincia,
            credentials,
            setSnackbarInfo
        );

        setProvincias(provinciasResponse);
    };

    // queda comentado hasta que se implemente un filtro paisId para obtener localidades extrajeras
    // const getLocalidades = async (localidadId = null) => {
    //     const body = {
    //         service: "LocalidadGet",
    //         params: {
    //             localidadId: localidadId,
    //             localidadCod: null,
    //             localidadNombre: null,
    //         },
    //     };

    //     const localidadResponse = await RequestServiceGet(
    //         body,
    //         credentials,
    //         setSnackbarInfo
    //     );
    //     setLocalidades(localidadResponse);
    // };

    useEffect(() => {
        getProvinces();
    }, []);

    async function handleSubmit(values, setErrors) {
        for (const checkbox in checkboxes) {
            if (!checkboxes[checkbox]) {
                setErrors({
                    checkbox:
                        "Todos los checkbox deben estar tildados si los datos son correctos.",
                });
                return;
            }
        }

        setIsLoading(true);
        const dniFrenteSplit = dniFrente.split("data:image/jpeg;base64,")[1];
        const dniDorsoSplit = dniDorso.split("data:image/jpeg;base64,")[1];
        const recorteSplit = recorte.split("data:image/jpeg;base64,")[1];
        const selfieSplit = selfie.split("data:image/jpeg;base64,")[1];

        const args = {
            aplicacionCod: aplicacionCod,
            tipoDeDocumentoId: Number(values.documentTypeId),
            usuarioNroDeDocumentoX: values.documentNumber,
            usuarioSexo: values.gender,
            usuarioFechaDeNacimiento: values.birthdate,
            usuarioApellidos: values.lastname,
            usuarioNombres: values.name,
            usuarioVtoDeDocumento: values.vencimientoDocumento,
            usuarioMail: values.email,
            documentoFrenteImg: dniFrenteSplit,
            documentoDorsoImg: dniDorsoSplit,
            documentoFotoImg: recorteSplit,
            usuarioFotoImg: selfieSplit,
            usuarioDNIMatchScore: match,
        };
        if (!isExpirationDateNoRequired && values.phoneNumber) {
            args.usuarioCelular = values.phoneNumber;
        }
        if (!isAddressNoRequired) {
            args.domicilioCalle = values.street;
            args.domicilioCasa = values.streetNumber;
            args.domicilioPiso = values.aptFloor;
            args.domicilioUnidad = values.aptUnit;
            args.domicilioCPA = values.CP;
        }

        // execute check against RENAPER in case user is from ARG
        const result = await onBoardingManual(args, credentials, setSnackbarInfo);
        if (result.status.code === 1) {
            setTimeout(() => {
                if (location.pathname.includes(ROUTES.onboarding_manual.route)) {
                    navigate(ROUTES.onboarding_manual.route);
                }
                else {
                    navigate(ROUTES.no_usuarios.route);
                }
            }, 2500);

            setModalDetails((modalState) => ({ ...modalState, isOpen: false }));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (
            imagenSelfie.current &&
            imagenFrenteDNI.current &&
            imagenDorsoDNI.current &&
            imagenRecorte.current
        ) {
            imagenSelfie.current.style.transform = selfieRef.current.style.transform;
            imagenFrenteDNI.current.style.transform = frenteRef.current.style.transform;
            imagenDorsoDNI.current.style.transform = dorsoRef.current.style.transform;
            imagenRecorte.current.style.transform = recorteRef.current.style.transform;
        }
    }, [selfieRef, frenteRef, dorsoRef, recorteRef]);

    return (
        <Grid container display={"flex"} justifyContent={"space-around"}>
            <Formik
                initialValues={{
                    name: '',
                    lastname: '',
                    birthdate: moment(userInfo.usuarioNoUsuarioFechaDeNacimiento).format('YYYY-MM-DD'),
                    vencimientoDocumento: '',
                    documentTypeId: userInfo.documentType || userInfo.tipoDeDocumentoId,
                    documentNumber: userInfo.numeroDocumento || userInfo.usuarioNoUsuarioNroDeDocumentoX,
                    gender: userInfo.sexo || userInfo.usuarioNoUsuarioSexo,
                    email: userInfo.usuarioNoUsuarioMail ?? '',
                    phoneNumber: userInfo.usuarioNoUsuarioTelefonoCelular ?? '',
                    provinciaId: '',
                    localidadId: '',
                    street: '',
                    streetNumber: '',
                    aptFloor: '',
                    aptUnit: '',
                    CP: '',
                    checkbox: '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    lastname: Yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    birthdate: Yup.date(FORM_ERROR_MESSAGES.date).required(FORM_ERROR_MESSAGES.required),
                    documentNumber: Yup.number(FORM_ERROR_MESSAGES.number).required(FORM_ERROR_MESSAGES.required),
                    gender: Yup.string(FORM_ERROR_MESSAGES.text)
                        .oneOf(["M", "F", "X"], "Género inválido")
                        .required(FORM_ERROR_MESSAGES.required),
                    email: Yup.string(FORM_ERROR_MESSAGES.text)
                        .matches(
                            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                            "Formato inválido"
                        ),
                    phoneNumber: Yup.number(FORM_ERROR_MESSAGES.number).nullable(),
                })}
                onSubmit={(values, { setErrors, setSubmitting }) => {
                    try {
                        handleSubmit(values, setErrors);
                    } catch (e) {
                        setSnackbarInfo({
                            message: e.errmsg,
                            severity: "error",
                            open: true,
                        });
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors }) => (
                    <Form className={styles.inputs} noValidate>
                        <Grid
                            container
                            columns={12}
                            display={"flex"}
                        >
                            <Grid
                                item
                                sm={6}
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <div className={styles.column}>
                                    <div className={styles.userImage}>
                                        <div className={styles.imageWrapper}>
                                            <ZoomableImage
                                                alt={"Frente del DNI"}
                                                className={styles.image}
                                                ref={imagenFrenteDNI}
                                                src={dniFrente}
                                            />
                                        </div>
                                        <p className={styles.imageLabel}>Frente del DNI</p>
                                    </div>
                                    <div className={styles.userImage}>
                                        <div className={styles.imageWrapper}>
                                            <ZoomableImage
                                                alt="Dorso del DNI"
                                                className={styles.image}
                                                ref={imagenDorsoDNI}
                                                src={dniDorso}
                                            />
                                        </div>
                                        <p className={styles.imageLabel}>Dorso del DNI</p>
                                    </div>
                                    <div className={styles.userImage}>
                                        <div className={styles.imageWrapper}>
                                            <ZoomableImage
                                                alt="Selfie"
                                                className={styles.image}
                                                ref={imagenSelfie}
                                                src={selfie}
                                            />
                                        </div>
                                        <p className={styles.imageLabel}>Selfie</p>
                                    </div>
                                    <div className={styles.userImage}>
                                        <div className={styles.imageWrapper}>
                                            <ZoomableImage
                                                alt="Recorte"
                                                className={styles.image}
                                                ref={imagenRecorte}
                                                src={recorte}
                                            />
                                        </div>
                                        <p className={styles.imageLabel}>Recorte del DNI</p>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Grid
                                    container
                                    columns={12}
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        sm={6}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <FormikTextInput
                                            className={styles.input}
                                            disabled={false}
                                            labelText={"Nombres"}
                                            name="name"
                                            type={"text"}
                                        />

                                        <Checkbox
                                            className={styles.checkbox}
                                            color="primary"
                                            checked={checkboxes.first}
                                            onChange={(e) =>
                                                setCheckboxes({
                                                    ...checkboxes,
                                                    first: e.target.checked,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <FormikTextInput
                                            className={styles.input}
                                            disabled={false}
                                            labelText={"Apellidos"}
                                            name="lastname"
                                            type={"text"}
                                        />

                                        <Checkbox
                                            className={styles.checkbox}
                                            color="primary"
                                            checked={checkboxes.second}
                                            onChange={(e) =>
                                                setCheckboxes({
                                                    ...checkboxes,
                                                    second: e.target.checked,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={3}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <FormikTextInput
                                            className={styles.input}
                                            disabled={true}
                                            labelText={"Número de documento"}
                                            name="documentNumber"
                                            type={"text"}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={3}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <FormikSelectInput
                                            disabled={true}
                                            fullWidth={true}
                                            labelText={"Sexo"}
                                            name="gender"
                                        >
                                            <option value="">
                                                Seleccione una opción...
                                            </option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                            <option value="X">No Binario</option>
                                        </FormikSelectInput>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={6}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                    >
                                        <FormikTextInput
                                            className={styles.input}
                                            disabled={false}
                                            labelText={"Fecha de nacimiento"}
                                            name="birthdate"
                                            type={"date"}
                                        />

                                        <Checkbox
                                            className={styles.checkbox}
                                            color="primary"
                                            checked={checkboxes.third}
                                            onChange={(e) =>
                                                setCheckboxes({
                                                    ...checkboxes,
                                                    third: e.target.checked,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={7}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <FormikTextInput
                                            className={styles.input}
                                            disabled={false}
                                            labelText={"Email"}
                                            name="email"
                                            type={"email"}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={5}
                                        display={"flex"}
                                        justifyContent={"center"}
                                    >
                                        <FormikTextInput
                                            className={styles.input}
                                            disabled={false}
                                            labelText={"Número de telefono"}
                                            name="phoneNumber"
                                            type={"tel"}
                                        />
                                    </Grid>

                                    <Grid item display={"flex"} md={6}>
                                        <FormGroup >
                                            <FormControlLabel control={<Checkbox
                                                className={styles.checkbox}
                                                color="primary"
                                                checked={isExpirationDateNoRequired}
                                                onChange={(e) => {
                                                    setIsExpirationDateNoRequired(e.target.checked);
                                                    setCheckboxes({
                                                        ...checkboxes,
                                                        fourth: e.target.checked
                                                    });
                                                }}
                                            />} label={"No requiere fecha de vencimiento"} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item display={"flex"} md={6}>
                                        <FormGroup >
                                            <FormControlLabel control={<Checkbox
                                                className={styles.checkbox}
                                                color="primary"
                                                checked={isAddressNoRequired}
                                                onChange={(e) => {
                                                    setIsAddressNoRequired(e.target.checked);
                                                }}
                                            />} label={"No requiere domicilio"} />
                                        </FormGroup>
                                    </Grid>
                                    {
                                        !isExpirationDateNoRequired && (
                                            <Grid
                                                item
                                                sm={6}
                                                display={"flex"}
                                                justifyContent={"center"}
                                            >

                                                <FormikTextInput
                                                    className={styles.input}
                                                    disabled={false}
                                                    name="vencimientoDocumento"
                                                    labelText={"Fecha vencimiento DNI"}
                                                    type={"date"}
                                                />
                                                <Checkbox
                                                    className={styles.checkbox}
                                                    color="primary"
                                                    checked={checkboxes.fourth}
                                                    onChange={(e) =>
                                                        setCheckboxes({
                                                            ...checkboxes,
                                                            fourth: e.target.checked,
                                                        })
                                                    }
                                                />
                                            </Grid>
                                        )
                                    }
                                    {
                                        !isAddressNoRequired && (
                                            <>
                                                <Grid
                                                    item
                                                    sm={6}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >


                                                    <FormikSelectInput
                                                        disabled={!provincias.length}
                                                        fullWidth={true}
                                                        labelText={"Provincia"}
                                                        name="provinciaId"
                                                    >
                                                        <option value="">
                                                            Seleccione una opción...
                                                        </option>
                                                        {provincias?.map(
                                                            ({ provinciaId, provinciaNombre }) => (
                                                                <option
                                                                    key={provinciaId}
                                                                    value={provinciaId || ""}
                                                                >
                                                                    {provinciaNombre}
                                                                </option>
                                                            )
                                                        )}
                                                    </FormikSelectInput>
                                                </Grid>

                                                {/* queda comentado para cuando se implementen localidades para paises extranjeros */}
                                                {/* <Grid
                                item
                                sm={6}
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <FormikSelectInput
                                    disabled={false}
                                    fullWidth={true}
                                    labelText={"Localidad"}
                                    name="localidadId"
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                    {localidades?.map(
                                        ({ localidadId, localidadNombre }) => (
                                            <option
                                                key={localidadId}
                                                value={localidadId || ""}
                                            >
                                                {localidadNombre}
                                            </option>
                                        )
                                    )}
                                </FormikSelectInput>
                            </Grid> */}

                                                <Grid
                                                    item
                                                    sm={6}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >
                                                    <FormikTextInput
                                                        className="column"
                                                        disabled={false}
                                                        labelText={"Localidad"}
                                                        name="localidadId"
                                                        type={"text"}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    sm={6}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >
                                                    <FormikTextInput
                                                        className="column"
                                                        disabled={false}
                                                        labelText={"Calle"}
                                                        name="street"
                                                        type={"text"}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    sm={3}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >
                                                    <FormikTextInput
                                                        className="column"
                                                        disabled={false}
                                                        labelText={"Número"}
                                                        name="streetNumber"
                                                        type={"text"}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    sm={3}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >
                                                    <FormikTextInput
                                                        className="column"
                                                        disabled={false}
                                                        labelText={"Piso"}
                                                        name="aptFloor"
                                                        type={"text"}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    sm={3}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >
                                                    <FormikTextInput
                                                        className="column"
                                                        disabled={false}
                                                        labelText={"Departamento"}
                                                        name="aptUnit"
                                                        type={"text"}
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    sm={3}
                                                    display={"flex"}
                                                    justifyContent={"center"}
                                                >
                                                    <FormikTextInput
                                                        className="column"
                                                        disabled={false}
                                                        labelText={"Código postal"}
                                                        name="CP"
                                                        type={"text"}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }

                                    <Grid
                                        item
                                        sm={10}
                                        display={"flex"}
                                        alignItems={"center"}
                                        gap={"2em"}
                                        justifyContent={"center"}
                                        marginTop={'1em'}
                                    >
                                        <label>Match estimado entre selfie y DNI</label>
                                        <Slider
                                            aria-label="Match estimado entre selfie y DNI"
                                            value={match}
                                            max={100}
                                            min={60}
                                            onChange={(e, value) => setMatch(value)}
                                            step={1}
                                            sx={{ maxWidth: "50%" }}
                                        />
                                    </Grid>

                                    <FormButton
                                        inRequest={isLoading}
                                        newFormData={{ button: "Aprobar" }}
                                    />
                                    (*) Marque los checkboxes si los datos son correctos

                                    {isLoading && <CircularIndeterminate />}
                                    {
                                        errors.checkbox && (
                                            <ErrorMessage
                                                className={styles.errorMessage}
                                                component={"p"}
                                                name="checkbox"
                                            />
                                        )
                                    }
                                </Grid >
                            </Grid>
                        </Grid>
                    </Form >
                )
                }
            </Formik >
        </Grid>
    );
}
