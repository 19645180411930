export const INSIDE_DRAWER = {
    tables: {
        drawer_name: "Tablas",
        show_drawer: true,
        isOpen: false,
    },
    on_boarding: {
        drawer_name: "On Boarding",
        show_drawer: true,
        isOpen: false,
    },
};

export const ROUTES = {
    login: {
        route: "/",
        showMenuItem: false,
        needSuperAuth: false,
    },
    home: {
        route: "/home",
        wordKey: "Home",
        showMenuItem: false,
        needSuperAuth: false,
    },
    no_usuarios: {
        route: "/no_usuarios",
        wordKey: "Usuarios no usuarios",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: false,
    },
    usuarios_bo: {
        route: "/usuarios_bo",
        wordKey: "Usuarios backoffice",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: false,
    },
    no_usuarios_detail: {
        route: "/no_usuarios/detail",
        wordKey: "Usuarios no usuarios",
        showMenuItem: false,
        needSuperAuth: false,
        insideDrawer: false,
    },
    device_tipos: {
        route: "/device_tipos",
        wordKey: "Tipos de device",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    domicilio_tipos: {
        route: "/domicilio_tipos",
        wordKey: "Tipos de domicilio",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    telefono_tipos: {
        route: "/telefono_tipos",
        wordKey: "Tipos de teléfono",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    registro_tipos: {
        route: "/registro_tipos",
        wordKey: "Tipos de registro",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    imagen_tipo: {
        route: "/imagen_tipo",
        wordKey: "Tipos de imagen",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    documento_tipo: {
        route: "/documento_tipo",
        wordKey: "Tipos de documento",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    registro_estado: {
        route: "/registro_estado",
        wordKey: "Estados de registro",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    aplicaciones: {
        route: "/aplicaciones",
        wordKey: "Aplicaciones",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    roles: {
        route: "/roles",
        wordKey: "Roles",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    servicios: {
        route: "/servicios",
        wordKey: "Servicios",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    registro: {
        route: "/registro",
        wordKey: "Registro",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    paises: {
        route: "/paises",
        wordKey: "Países",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    localidad: {
        route: "/localidad",
        wordKey: "Localidad",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    devices: {
        route: "/devices",
        wordKey: "Dispositivos",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    devices_details: {
        route: "/devices_details",
        wordKey: "Dispositivos",
        showMenuItem: false,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    estado_civil: {
        route: "/estado_civil",
        wordKey: "Estado Civil",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    factor_biometrico: {
        route: "/factor_biometrico",
        wordKey: "Factor Biométrico",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    eventos_de_sesion: {
        route: "/eventos_de_sesion",
        wordKey: "Eventos de sesión",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    rubros: {
        route: "/rubros",
        wordKey: "Rubros de merchant",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    zonas_denegadas: {
        route: "/zonas_denegadas",
        wordKey: "Zonas Denegadas",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    feriados: {
        route: "/feriados",
        wordKey: "Feriados",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    feriados_series: {
        route: "/feriados_series",
        wordKey: "Series de Feriados",
        showMenuItem: false,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    actividades_afip: {
        route: "/actividades_afip",
        wordKey: "Actividades AFIP",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    condiciones_afip: {
        route: "/condiciones_afip",
        wordKey: "Condiciones AFIP",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    imagen_formats: {
        route: "/imagen_formats",
        wordKey: "Formato de imágenes",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    imagen_params: {
        route: "/imagen_params",
        wordKey: "Parámetros de imágenes",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    imagen_varias: {
        route: "/imagen_varias",
        wordKey: "Imágenes varias",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    monedas: {
        route: "/monedas",
        wordKey: "Monedas",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    monedas_series: {
        route: "/monedas_series",
        wordKey: "Monedas Series",
        showMenuItem: false,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
    onboarding_manual: {
        route: "/onboarding_manual",
        wordKey: "Onboarding Manual",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: false,
    },
    usuarios: {
        route: "/usuarios",
        wordKey: "Usuarios",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.on_boarding.drawer_name,
    },
    usuarios_detail_dashboard: {
        route: "/usuarios_detalle",
        wordKey: "Usuarios",
        showMenuItem: false,
        needSuperAuth: false,
        insideDrawer: false,
    },
    blacklist: {
        route: "/blacklist",
        wordKey: "Blacklist",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.on_boarding.drawer_name,
    },
    params_generales: {
        route: "/params_generales",
        wordKey: "Parámetros Generales",
        showMenuItem: true,
        needSuperAuth: false,
        insideDrawer: INSIDE_DRAWER.tables.drawer_name,
    },
};
