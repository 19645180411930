import { FALLBACK_URL } from "consts/env";

export const checkAuth = () => {
    if (localStorage.getItem("miaToken") && localStorage.getItem("sessionId")) {
        return {
            miaToken: localStorage.getItem("miaToken"),
            sessionId: localStorage.getItem("sessionId"),
        };
    }

    const data = new URLSearchParams(window.location.search);
    const sessionId = data.get("session");
    const miaToken = data.get("miaToken");

    if (miaToken && sessionId) {
        localStorage.setItem("sessionId", sessionId);
        localStorage.setItem("miaToken", miaToken);

        return {
            miaToken: miaToken,
            sessionId: sessionId,
        };
    }

    window.location.assign(FALLBACK_URL);
};
