import React, { useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import { FormikTextInput } from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function UpdateModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const handleSubmit = async ({
        tipoDeImagenId,
        tipoDeImagenCod,
        tipoDeImagenNombre,
        tipoDeImagenBucket,
        tipoDeImagenFolder,
        tipoDeImagenVersion,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "TipoDeImagenUpd",
            params: {
                tipoDeImagenId: tipoDeImagenId,
                tipoDeImagenCod: tipoDeImagenCod,
                tipoDeImagenNombre: tipoDeImagenNombre,
                tipoDeImagenBucket: tipoDeImagenBucket,
                tipoDeImagenFolder: tipoDeImagenFolder,
                tipoDeImagenVersion: tipoDeImagenVersion,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.UPDATE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    return (
        <Formik
            initialValues={{
                tipoDeImagenId: row.row.id,
                tipoDeImagenCod: row.row.tipoDeImagenCod,
                tipoDeImagenNombre: row.row.tipoDeImagenNombre,
                tipoDeImagenBucket: row.row.tipoDeImagenBucket,
                tipoDeImagenFolder: row.row.tipoDeImagenFolder,
                tipoDeImagenVersion: row.row.tipoDeImagenVersion,
            }}
            validationSchema={Yup.object({
                tipoDeImagenNombre: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(45, "Máximo 45 caracteres"),
                tipoDeImagenBucket: Yup.string(
                    FORM_ERROR_MESSAGES.text
                ).required(FORM_ERROR_MESSAGES.required),
                tipoDeImagenFolder: Yup.string(
                    FORM_ERROR_MESSAGES.text
                ).required(FORM_ERROR_MESSAGES.required),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <FormikTextInput
                        fullWidth={true}
                        name="tipoDeImagenNombre"
                        labelText={"Nombre"}
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="tipoDeImagenBucket"
                        labelText={"Bucket"}
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="tipoDeImagenFolder"
                        labelText={"Folder"}
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Enviar" }}
                        hasConfirmationModal={true}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
