import axiosPost from "./axiosPost";

export const RequestServiceGet = async (
    body,
    credentials,
    setSnackbarInfo = () => {}
) => {
    try {
        const result = await axiosPost("Y", body, credentials);

        if (
            result?.status?.action == "E" &&
            (result?.status?.errcode || result?.status?.errmsg)
        ) {
            setSnackbarInfo({
                message: result.status.errmsg,
                severity: "error",
                open: true,
            });
            setTimeout(() => {
                setSnackbarInfo({ open: false });
            }, 2500);
        }
        if (
            body.params.page !== null &&
            body.params.page !== undefined &&
            body.params.pageSize !== null &&
            body.params.pageSize !== undefined
        ) {
            return {
                result: result.result,
                total: result.status.id,
            };
        }
        return result.result;
    } catch (e) {
        setSnackbarInfo({
            message: e.errmsg,
            severity: "error",
            open: true,
        });
        setTimeout(() => {
            setSnackbarInfo((data) => !data.open);
        }, 2500);
    }
};
