import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Buttons, HeaderButton, XGridDemo } from "components";

import { LupaIcon, ReloadIcon } from "assets/icons";

import { usuariosNoUsuarios as titles } from "consts/tableTitles";
import { ROUTES } from "consts/routes";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
    routeInfo,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import styles from "styles/pages/Tables.module.scss";

export default function UsuariosNoUsuarios() {
    const [isLoading, setIsLoading] = useState(false);

    const [usersNoUsers, setUsersNoUsers] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const [row, setRow] = useRecoilState(rowSelected);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setRoute = useSetRecoilState(routeInfo);

    async function handleUpdateTable(
        usuarioNoUsuarioId = null,
        tipoDeDocumentoId = null,
        usuarioNoUsuarioNroDeDocumento = null,
        usuarioNoUsuarioSexo = null,
        usuarioNoUsuarioFechaDeNacimiento = null
    ) {
        setIsLoading(true);

        const body = {
            service: "UsuarioNoUsuarioBasicosGet",
            params: {
                usuarioNoUsuarioId,
                tipoDeDocumentoId,
                usuarioNoUsuarioNroDeDocumento,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        const userNoUserPartial = result.map((item) => {
            const {
                usuarioNoUsuarioId,
                tipoDeDocumentoId,
                tipoDeDocumentoCod,
                tipoDeDocumentoNombre,
                usuarioNoUsuarioNroDeDocumentoX,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
                usuarioNoUsuarioDeviceId,
                usuarioNoUsuarioGCMToken,
                usuarioNoUsuarioEquipoMarca,
                usuarioNoUsuarioEquipoModelo,
                aplicacionId,
                aplicacionCod,
                aplicacionNombre,
                usuarioNoUsuarioAppVersion,
                usuarioNoUsuarioTelefonoCelular,
                usuarioNoUsuarioMail,
                usuarioNoUsuarioLatitud,
                usuarioNoUsuarioLongitud,
                usuarioNoUsuarioFechaAlta,
                usuarioNoUsuarioUID,
                usuarioNoUsuarioUFH,
                usuarioNoUsuarioVersion,
            } = item;

            return {
                id: usuarioNoUsuarioId,
                tipoDeDocumentoId,
                tipoDeDocumentoCod,
                tipoDeDocumentoNombre,
                usuarioNoUsuarioNroDeDocumentoX,
                usuarioNoUsuarioSexo,
                usuarioNoUsuarioFechaDeNacimiento,
                usuarioNoUsuarioDeviceId,
                usuarioNoUsuarioGCMToken,
                usuarioNoUsuarioEquipoMarca,
                usuarioNoUsuarioEquipoModelo,
                aplicacionId,
                aplicacionCod,
                aplicacionNombre,
                usuarioNoUsuarioAppVersion,
                usuarioNoUsuarioTelefonoCelular,
                usuarioNoUsuarioMail,
                usuarioNoUsuarioLatitud,
                usuarioNoUsuarioLongitud,
                usuarioNoUsuarioFechaAlta,
                usuarioNoUsuarioUID,
                usuarioNoUsuarioUFH,
                usuarioNoUsuarioVersion,
                [titles[0]]: usuarioNoUsuarioNroDeDocumentoX ? usuarioNoUsuarioNroDeDocumentoX.toLocaleString('es') : '',
                [titles[1]]: usuarioNoUsuarioSexo,
                [titles[2]]: usuarioNoUsuarioFechaDeNacimiento
                    ? usuarioNoUsuarioFechaDeNacimiento.slice(0, 10)
                    : "",
                [titles[3]]: usuarioNoUsuarioTelefonoCelular,
                [titles[4]]: usuarioNoUsuarioMail,
                [titles[5]]: aplicacionCod,
                [titles[6]]: usuarioNoUsuarioFechaAlta,
            };
        });

        setUsersNoUsers(userNoUserPartial);
        setIsLoading(false);
    }

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
        setRow({ status: false, row: {} });
    }, []);

    return (
        <section className={styles.section}>
            <Buttons>
                <>
                    {row.status ? (
                        <Link
                            to={`/no_usuarios/detail?no_user_id=${row.row.id}`}
                        >
                            <HeaderButton
                                text={"Ver Detalle"}
                                icon={LupaIcon}
                                onClick={() =>
                                    setRoute({
                                        route: ROUTES["no_usuarios_detail"],
                                    })
                                }
                            />
                        </Link>
                    ) : (
                        <HeaderButton
                            text={"Actualizar página"}
                            icon={ReloadIcon}
                            updateList={handleUpdateTable}
                        />
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={usersNoUsers}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
