import React, { useEffect, useState } from "react";
import { useImages } from "hooks/useImages";

import { Button, CircularIndeterminate } from "components";
import { InputLabel } from "@mui/material";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { RequestServiceActions } from "services/mia-services/RequestServiceActions";
import { base64Convert } from "utils/base64Convert";

import stylesForm from "styles/components/UI/ModalForm.module.scss";
import styles from "styles/pages/usuariosBO/OmniModal.module.scss";

export default function OmniModal({ closeModal, updateModalList, userId }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [omniResult, setOmniResult] = useState(null);
    const [IDXSelfieImg, setIDXSelfieImg] = useState(null);

    const { downloadIMG } = useImages(setIDXSelfieImg, IDXSelfieImg);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const getOmni = async () => {
        setIsLoading(true);

        const body = {
            service: "EndUserOnboardOMNIBOGet",
            params: {
                usuarioId: userId
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setOmniResult(result[0]);

        downloadIMG([result[0]?.imagePathSELFIE]);

        setIsLoading(false);
    };

    const handleSubmit = async ({ usuarioId, usuarioFotoImg }) => {
        setInRequest(true);

        const body = {
            service: "Usuario3DiViBOIns",
            params: {
                usuarioId: usuarioId,
                usuarioFotoImg: !usuarioFotoImg ? IDXSelfieImg[0].split("data:image/jpeg;base64,")[1] : usuarioFotoImg.split("data:image/jpeg;base64,")[1],
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );

        closeModal();
        updateModalList();

        setInRequest(false);
    };

    useEffect(() => {
        credentials && getOmni();
    }, [credentials]);

    if (isLoading) return <CircularIndeterminate />;

    return (
        <Formik
            initialValues={{
                usuarioId: userId,
                usuarioFotoImg: omniResult?.imagePathSELFIE
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
                usuarioId: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {(({ setFieldValue, values }) =>
                <Form className={stylesForm.inputs} noValidate>
                    <div className={styles.container}>
                        <div className={styles.column}>
                            <InputLabel className={styles.imageLabel}>
                                Subir nueva selfie
                            </InputLabel>
                            {values.usuarioFotoImg ? <img alt="usuario imagen" className={stylesForm.image} src={values.usuarioFotoImg} /> : <CircularIndeterminate />}
                            <input
                                accept="image/*"
                                className={stylesForm.imageInput}
                                name="usuarioFotoImg"
                                onChange={(e) => base64Convert(e, setFieldValue, "usuarioFotoImg", "image/jpeg")}
                                type={"file"}
                            />
                        </div>
                        <div className={styles.buttonRow}>
                            <Button
                                disabled={inRequest}
                                type="submit"
                                texto={"Confirmar"}
                            />

                            <Button
                                disabled={inRequest}
                                onClick={event => { event.preventDefault(); closeModal(); }}
                                texto={"Cerrar"}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
