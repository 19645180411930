import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Grid } from "@mui/material";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";
import { CircularIndeterminate, FormButton } from "components";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    routeInfo,
    snackbarData,
    breadcrumbState,
} from "recoil/GlobalState";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";
import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/pages/Tables.module.scss";

export default function Devices() {
    const [searchParams] = useSearchParams();

    const credentials = useRecoilValue(userCredentials);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setBreadCrumbs = useSetRecoilState(breadcrumbState);

    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deviceLocalState, setDeviceLocalState] = useState([]);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [deviceId, setDeviceId] = useState(null);
    const [servicioIndex, setServicioIndex] = useState([]);
    const [servicios, setServicios] = useState([]);

    const handleUpdateTable = async () => {
        await formatDevice(deviceId);
    };

    const getServices = async (servicioId = null, servicioCod = null) => {
        const body = {
            service: "ServicioGet",
            params: {
                servicioId: servicioId,
                servicioCod: servicioCod,
            },
        };

        const servicesResponse = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setServicios(servicesResponse);

        // set services indexes for formik validation
        servicesResponse.map((service) => {
            setServicioIndex((serviceIndex) => [
                ...serviceIndex,
                service.servicioId,
            ]);
        });
    };

    const getDeviceTypes = async (
        deviceTipoId = null,
        deviceTipoCod = null
    ) => {
        const body = {
            service: "DeviceTipoGet",
            params: {
                deviceTipoId: deviceTipoId,
                deviceTipoCod: deviceTipoCod,
            },
        };
        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setDeviceTypes(result);
    };

    const handleSubmit = async ({
        deviceId,
        deviceTipoId,
        clienteId,
        deviceMarca,
        deviceModelo,
        deviceSerie,
        deviceIdentificacion,
        deviceFechaCompra,
        deviceStatus,
        deviceMAC,
        deviceESTipo,
        deviceLicencia,
        deviceUmbral,
        deviceDelta,
        deviceTiempoAccion,
        deviceVersion,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "DeviceUpd",
            params: {
                deviceId: deviceId,
                deviceTipoId: deviceTipoId,
                clienteId: clienteId,
                deviceMarca: deviceMarca,
                deviceModelo: deviceModelo,
                deviceSerie: deviceSerie,
                deviceIdentificacion: deviceIdentificacion,
                deviceMAC: deviceMAC,
                deviceFechaCompra: deviceFechaCompra,
                deviceESTipo: deviceESTipo,
                deviceStatus: deviceStatus,
                deviceLicencia: deviceLicencia,
                deviceUmbral: deviceUmbral,
                deviceDelta: deviceDelta,
                deviceTiempoAccion: deviceTiempoAccion,
                deviceVersion: deviceVersion,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.UPDATE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    const formatDevice = async (
        deviceId = null,
        tipoDeviceId = null,
        clienteId = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "DeviceGet",
            params: {
                deviceId: deviceId,
                tipoDeviceId: tipoDeviceId,
                clienteId: clienteId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const devicePartial = result.reduce((acc, item) => {
            const {
                deviceId,
                deviceTipoId,
                clienteId,
                clienteNombre,
                deviceMarca,
                deviceModelo,
                deviceSerie,
                deviceIdentificacion,
                deviceFechaCompra,
                deviceGCMToken,
                deviceMAC,
                deviceStatus,
                deviceSincroFH,
                deviceESTipo,
                deviceLicencia,
                deviceUmbral,
                deviceDelta,
                deviceTiempoAccion,
                deviceUID,
                deviceUFH,
                deviceVersion,
            } = item;

            const deviceType = {
                id: deviceId,
                deviceTipoId: deviceTipoId,
                clienteId: clienteId,
                clienteNombre: clienteNombre,
                deviceMarca: deviceMarca,
                deviceModelo: deviceModelo,
                deviceSerie: deviceSerie,
                deviceIdentificacion: deviceIdentificacion,
                deviceFechaCompra: deviceFechaCompra,
                deviceGCMToken: deviceGCMToken,
                deviceMAC: deviceMAC,
                deviceStatus: deviceStatus,
                deviceSincroFH: deviceSincroFH,
                deviceESTipo: deviceESTipo,
                deviceLicencia: deviceLicencia,
                deviceUmbral: deviceUmbral,
                deviceDelta: deviceDelta,
                deviceTiempoAccion: deviceTiempoAccion,
                deviceUID: deviceUID,
                deviceUFH: deviceUFH,
                deviceVersion: deviceVersion,
            };

            return [...acc, deviceType];
        }, []);

        setDeviceLocalState(devicePartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;

        const deviceId = searchParams.get("device_id");

        setDeviceId(Number(deviceId));

        // prevent visiting page without device id
        if (!deviceId) {
            window.location.replace(ROUTES.devices.route);
        }

        formatDevice(Number(deviceId));
        getDeviceTypes();
        getServices();
    }, [credentials]);

    useEffect(() => {
        deviceLocalState[0]
            ? setBreadCrumbs([
                  { name: ROUTES.devices.wordKey, route: ROUTES.devices.route },
                  {
                      name: `Dispositivo ${deviceLocalState[0]?.deviceIdentificacion}`,
                      route: "/devices",
                  },
              ])
            : setBreadCrumbs([
                  { name: ROUTES.devices.wordKey, route: ROUTES.devices.route },
              ]);
    }, [deviceLocalState]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.noTableSection}>
            {!deviceLocalState.length ? (
                <CircularIndeterminate />
            ) : (
                <Formik
                    initialValues={{
                        clienteNombre: deviceLocalState[0]?.clienteNombre,
                        servicioId: deviceLocalState[0]?.servicioId,
                        deviceId: deviceLocalState[0]?.id,
                        deviceTipoId: deviceLocalState[0]?.deviceTipoId,
                        clienteId: deviceLocalState[0]?.clienteId,
                        deviceMarca: deviceLocalState[0]?.deviceMarca,
                        deviceModelo: deviceLocalState[0]?.deviceModelo,
                        deviceSerie: deviceLocalState[0]?.deviceSerie,
                        deviceIdentificacion:
                            deviceLocalState[0]?.deviceIdentificacion,
                        deviceFechaCompra:
                            deviceLocalState[0]?.deviceFechaCompra,
                        deviceGCMToken: deviceLocalState[0]?.deviceGCMToken,
                        deviceMAC: deviceLocalState[0]?.deviceMAC,
                        deviceStatus: deviceLocalState[0]?.deviceStatus,
                        deviceESTipo: deviceLocalState[0]?.deviceESTipo,
                        deviceLicencia: deviceLocalState[0]?.deviceLicencia,
                        deviceUmbral: deviceLocalState[0]?.deviceUmbral,
                        deviceDelta: deviceLocalState[0]?.deviceDelta,
                        deviceTiempoAccion:
                            deviceLocalState[0]?.deviceTiempoAccion,
                        deviceUID: deviceLocalState[0]?.deviceUID,
                        deviceUFH: deviceLocalState[0]?.deviceUFH,
                        deviceVersion: deviceLocalState[0]?.deviceVersion,
                    }}
                    validationSchema={Yup.object({
                        clienteNombre: Yup.string(FORM_ERROR_MESSAGES.text),
                        servicioId: Yup.string(FORM_ERROR_MESSAGES.text).oneOf(
                            servicioIndex,
                            "Servicio seleccionado inválido"
                        ),
                        deviceTipoId: Yup.string(FORM_ERROR_MESSAGES.text)
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(["1", "2"]),
                        deviceMarca: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceModelo: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceSerie: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceIdentificacion: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceFechaCompra: Yup.date(
                            FORM_ERROR_MESSAGES.date
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceGCMToken: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceMAC: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).required(FORM_ERROR_MESSAGES.required),
                        deviceStatus: Yup.string(FORM_ERROR_MESSAGES.text)
                            .required(FORM_ERROR_MESSAGES.required)
                            .oneOf(["A", "B", "S"]),
                        deviceESTipo: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ).oneOf(["E", "S"]),
                        deviceLicencia: Yup.string(FORM_ERROR_MESSAGES.text),
                        deviceUmbral: Yup.string(FORM_ERROR_MESSAGES.text),
                        deviceDelta: Yup.string(FORM_ERROR_MESSAGES.text),
                        deviceTiempoAccion: Yup.string(
                            FORM_ERROR_MESSAGES.text
                        ),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        try {
                            handleSubmit(values);
                        } catch (e) {
                            setSnackbarInfo({
                                message: e.errmsg,
                                severity: "error",
                                open: true,
                            });
                            setTimeout(() => {
                                setSnackbarInfo((data) => !data.open);
                            }, 3000);
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    <Form className={styles.inputs} noValidate>
                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: "center" }}
                        >
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    fullWidth={true}
                                    name="clienteNombre"
                                    labelText={"Nombre cliente"}
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                </FormikSelectInput>
                            </Grid>

                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="servicioId"
                                    labelText={"Servicio"}
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                    {servicios?.map(
                                        ({ servicioId, servicioNombre }) => (
                                            <option
                                                key={servicioId}
                                                value={servicioId || ""}
                                            >
                                                {servicioNombre}
                                            </option>
                                        )
                                    )}
                                </FormikSelectInput>
                            </Grid>

                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="deviceTipoId"
                                    labelText={"Tipo"}
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                    {deviceTypes?.map(
                                        ({
                                            deviceTipoId,
                                            deviceTipoNombre,
                                        }) => (
                                            <option
                                                key={deviceTipoId}
                                                value={deviceTipoId || ""}
                                            >
                                                {deviceTipoNombre}
                                            </option>
                                        )
                                    )}
                                </FormikSelectInput>
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceMarca"
                                    labelText={"Marca"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceModelo"
                                    labelText={"Modelo"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceSerie"
                                    labelText={"Serie"}
                                />
                            </Grid>

                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceIdentificacion"
                                    labelText={"Identificación"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceFechaCompra"
                                    labelText={"Fecha de compra"}
                                    type="date"
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceGCMToken"
                                    labelText={"GCM Token"}
                                />
                            </Grid>

                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceMAC"
                                    labelText={"MAC"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="deviceStatus"
                                    labelText={"Estado"}
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                    <option value="A">Alta</option>
                                    <option value="B">Baja</option>
                                    <option value="S">Suspendido</option>
                                </FormikSelectInput>
                            </Grid>

                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikSelectInput
                                    defaultValue={""}
                                    fullWidth={true}
                                    name="deviceESTipo"
                                    labelText={"Tipo"}
                                >
                                    <option value="">
                                        Seleccione una opción...
                                    </option>
                                    <option value="E">Entrada</option>
                                    <option value="S">Salida</option>
                                </FormikSelectInput>
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceLicencia"
                                    labelText={"Licencia"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceUmbral"
                                    labelText={"Umbral"}
                                />
                            </Grid>

                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceDelta"
                                    labelText={"Delta"}
                                />
                            </Grid>
                            <Grid item sm={12} md={4} textAlign="center">
                                <FormikTextInput
                                    fullWidth={true}
                                    name="deviceTiempoAccion"
                                    labelText={"Tiempo de acción"}
                                />
                            </Grid>
                        </Grid>

                        <FormButton
                            hideCancelButton={true}
                            inRequest={inRequest}
                            newFormData={{ button: "Modificar" }}
                            hasConfirmationModal={true}
                        />
                        {isLoading && <CircularIndeterminate />}
                    </Form>
                </Formik>
            )}
        </section>
    );
}
