import axios from "axios";
import { APLICACION_COD, BASE_URL, FALLBACK_URL } from "consts/env";

export default async function axiosPost(
    loginReq,
    body,
    { miaToken, sessionId, xApiKey, xSupplementaryToken, XOriginId = null }
) {
    const serviceURL = BASE_URL;
    const headers = {
        "Content-Type": "application/json",
    };

    if (loginReq === "Y") {
        headers["x-hooli-token"] = miaToken || localStorage.getItem("miaToken");
        headers["x-session-id"] = sessionId || localStorage.getItem("sessionId");
        headers["x-origin-id"] = XOriginId || APLICACION_COD;
        if (xApiKey) headers["x-api-key"] = xApiKey || null;
        if (xSupplementaryToken) headers["x-supplementary-token"] = xSupplementaryToken || null;
    }

    try {
        const result = await (
            await axios.post(serviceURL, body, { headers })
        ).data;

        if (!result || result === null || result === undefined) {
            return {
                status: {
                    code: 0,
                    errcode: 99999,
                    errmsg: "Error inesperado en request",
                    action: "U",
                    id: null,
                },
                result: [],
            };
        }

        if (result.status.errmsg.includes("expirado")) {
            localStorage.clear();
            window.location.assign(FALLBACK_URL);
        }

        return result;
    } catch (err) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: "U",
                id: null,
            },
            result: [],
        };
    }
}
