import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import * as yup from "yup";
import MaskedInput from "react-text-mask";

import { endUserCuilCuitGet } from "services/mia-services/EndUser/EndUserCuilCuitGet";
import { endUserCuilCuitUpd } from "services/mia-services/EndUser/EndUserCuilCuitUpd";

import { Button } from "components";

import { useSetRecoilState, useRecoilValue } from "recoil";
import { userCredentials, snackbarData } from "recoil/GlobalState";

import styles from "styles/pages/usuariosBO/CuilCuit.module.scss";

export default function CuilCuitModal({ closeModal, updateModalList, userId }) {
    const [cuit, setCuit] = useState("");
    const [usuarioVersion, setUsuarioVersion] = useState(null);
    const [inRequest, setInRequest] = useState(false);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);

    useEffect(() => {
        const params = { usuarioId: userId };

        const payload = { ...credentials, params };
        (async () => {
            const data = await endUserCuilCuitGet(payload);
            setCuit(data.usuarioCUIT);
            setUsuarioVersion(data.usuarioVersion);
        })();
    }, [credentials]);

    async function handleClick(cuit, codigo) {
        const params = {
            usuarioId: userId,
            estadoDeRegistroCod: codigo,
            usuarioCUIT: cuit,
            usuarioVersion
        };

        const payload = { ...credentials, params };

        setInRequest(true);

        const response = await endUserCuilCuitUpd(payload);
        if (response) {
            if (response.status.code === 1) {
                setSnackbarInfo({
                    message: "CUIT Confirmado",
                    severity: "success",
                    open: true,
                });

                setInRequest(false);

                setTimeout(() => {
                    closeModal();
                    updateModalList();
                }, 2500);
            } else {
                setSnackbarInfo({
                    message: response.status.errmsg,
                    severity:
                        response.status.action === "W" ? "warning" : "error",
                    open: true,
                });

                setInRequest(false);
            }
        }
    }

    function checkCuit(cuit) {
        const documentNumber = cuit
            .substring(0, cuit.length - 1)
            .replaceAll("_", "")
            .replaceAll("-", "")
            .trim();

        let factor = 2;
        let resultado = 0;

        for (let i = documentNumber.length - 1; i >= 0; i--) {
            resultado = resultado + parseInt(documentNumber[i]) * factor;
            factor = factor == 7 ? 2 : factor + 1;
        }

        const resto = resultado % 11;
        const control = 11 - resto;

        const cuitFormateado = cuit
            .replaceAll("_", "")
            .replaceAll("-", "")
            .trim();
        return (
            control.toString() ===
            cuitFormateado.substring(cuitFormateado.length - 1)
        );
    }

    const validationSchema = yup.object({
        cuit: yup
            .string()
            .test("Control", "El ultimo numero es incorrecto", (value) => {
                if (value) return checkCuit(value);
                return;
            })
            .required("Ingrese un cuit"),
    });

    const formik = useFormik({
        initialValues: { cuit },
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => handleClick(values.cuit, "A"),
    });

    function mask(values) {
        const length = values
            .replaceAll("_", "")
            .replaceAll("-", "")
            .trim().length;
        return length === 10
            ? [
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
            ]
            : [
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
            ];
    }

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Confirmar CUIT / CUIL</h2>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <label htmlFor="cuit" className={styles.label}>
                    CUIT
                </label>
                <MaskedInput
                    name="cuit"
                    value={formik.values.cuit}
                    onChange={formik.handleChange}
                    mask={mask}
                    className={styles.input}
                    guide={false}
                    disabled={inRequest}
                />
                {formik.touched.cuit && formik.errors.cuit && (
                    <p>{formik.errors.cuit}</p>
                )}
                <div className={styles.buttonRow}>
                    <Button
                        disabled={inRequest}
                        onClick={() =>
                            window.open(
                                "https://www.anses.gob.ar/consulta/constancia-de-cuil",
                                "_blank"
                            )
                        }
                        texto={"Consulta online"}
                        type="button"
                    />

                    <Button
                        disabled={inRequest}
                        texto={"Confirmar"}
                    />

                    <Button
                        disabled={inRequest}
                        onClick={event => { event.preventDefault(); closeModal(); }}
                        texto={"Cerrar"}
                    />
                </div>
            </form>
        </div>
    );
}
