import React from "react";
import App from "./App";

import { createRoot } from "react-dom/client";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "@mui/material";
import { theme } from "styles/theme/theme";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <RecoilRoot>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </RecoilRoot>
);
