export const FORM_ERROR_MESSAGES = {
    required: "Este campo es requerido",
    number: "Debe ser un número",
    text: "Debe ser un texto",
    date: "Debe tener formato de fecha",
};

export const ACTION_REQUEST_MESSAGES = {
    UPDATE_SUCCESS: "Edición exitosa",
    INSERT_SUCCESS: "Creación exitosa",
    DELETE_SUCCESS: "Borrado exitoso",
    DEFAULT: "Proceso exitoso",
};
