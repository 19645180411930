import React, { useEffect, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
    modalData,
    rowSelected,
    snackbarData,
    userCredentials,
} from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/components/UI/ModalForm.module.scss";

export default function UpdateModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [paises, setPaises] = useState([]);
    const [paisIndexes, setPaisIndexes] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [provinciasIndex, setProvinciasIndex] = useState([]);
    const [paisSelected, setPaisSelected] = useState("");

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const row = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);

    const getCountries = async () => {
        const bodyPais = {
            service: "PaisGet",
            params: { paisId: null, paisCod: null },
        };

        const paisesResponse = await RequestServiceGet(
            bodyPais,
            credentials,
            setSnackbarInfo
        );
        setPaises(paisesResponse);

        // set countries indexes for formik validation
        paisesResponse.map((pais) => {
            setPaisIndexes((paisIndexes) => [...paisIndexes, pais.paisId]);
        });
    };

    const handleProvinces = async () => {
        const bodyProvincia = {
            service: "ProvinciaGet",
            params: {
                provinciaId: null,
                paisId: paisSelected ? Number(paisSelected) : row.row.paisId,
                provinciaCod: null,
                provinciaNombre: null,
            },
        };

        const provinciasResponse = await RequestServiceGet(
            bodyProvincia,
            credentials,
            setSnackbarInfo
        );
        setProvincias(provinciasResponse);

        // set provinces indexes for formik validation
        provinciasResponse.map((provincia) => {
            setProvinciasIndex((provinciasIndex) => [
                ...provinciasIndex,
                provincia.provinciaId,
            ]);
        });
    };

    const handleSubmit = async ({
        documentoTipoId,
        documentoTipoCod,
        documentoTipoNombre,
        documentoTipoAmbito,
        provinciaId,
        documentoTipoVersion,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "DocumentoTipoUpd",
            params: {
                documentoTipoId: documentoTipoId,
                documentoTipoCod: documentoTipoCod,
                documentoTipoNombre: documentoTipoNombre,
                documentoTipoAmbito: documentoTipoAmbito,
                paisId: Number(paisSelected),
                provinciaId: Number(provinciaId),
                documentoTipoVersion: documentoTipoVersion,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.UPDATE,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (paisSelected || row.row.paisId) handleProvinces();
    }, [paisSelected]);

    useEffect(() => {
        getCountries();
    }, []);

    return (
        <Formik
            initialValues={{
                documentoTipoId: row.row.id,
                documentoTipoCod: row.row.tipoDeDocumentoCod,
                documentoTipoNombre: row.row.tipoDeDocumentoNombre,
                documentoTipoAmbito: row.row.tipoDeDocumentoAmbito,
                paisId: row.row.paisId,
                provinciaId: row.row.provinciaId,
                documentoTipoVersion: row.row.tipoDeDocumentoVersion,
            }}
            validationSchema={Yup.object({
                documentoTipoNombre: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(45, "Máximo 45 caracteres"),
                documentoTipoAmbito: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(["N", "P"]),
                paisId: Yup.number(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(paisIndexes, "País seleccionado inválido"),
                provinciaId: Yup.number(FORM_ERROR_MESSAGES.text).oneOf(
                    provinciasIndex,
                    "Provincia seleccionada inválida"
                ),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ setFieldValue }) => (
                <Form className={styles.inputs} noValidate>
                    <div className={styles.column}>
                        <FormikTextInput
                            fullWidth={true}
                            name="documentoTipoNombre"
                            labelText={"Nombre"}
                        />

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="documentoTipoAmbito"
                            labelText={"Ámbito"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="N">Nacionalidad</option>
                            <option value="P">Provincia</option>
                        </FormikSelectInput>

                        <FormikSelectInput
                            // disabled={!paises.length}
                            defaultValue={""}
                            fullWidth={true}
                            name="paisId"
                            labelText={"País"}
                            onChange={(e) => {
                                setPaisSelected(e.target.value),
                                    setFieldValue("paisId", e.target.value);
                            }}
                        >
                            <option value="">Seleccione una opción...</option>
                            {paises?.map(({ paisId, paisNombre }) => (
                                <option key={paisId} value={paisId}>
                                    {paisNombre}
                                </option>
                            ))}
                        </FormikSelectInput>

                        <FormikSelectInput
                            disabled={!provincias.length}
                            defaultValue={""}
                            fullWidth={true}
                            name="provinciaId"
                            labelText={"Provincia"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {provincias?.map(
                                ({ provinciaId, provinciaNombre }) => (
                                    <option
                                        key={provinciaId}
                                        value={provinciaId || ""}
                                    >
                                        {provinciaNombre}
                                    </option>
                                )
                            )}
                        </FormikSelectInput>

                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Enviar" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                </Form>
            )}
        </Formik>
    );
}
