import React, { useEffect, useRef, useState } from "react";
import {
    GoogleMap,
    Marker,
    DrawingManager,
    Polygon,
    useJsApiLoader,
} from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "consts/env";

export default function GgleMap({
    setResponsePolygons = () => { },
    polygonPaths = [],
    isPolygonEditable = true,
    lat = null,
    lng = null,
    style = null,
    enableDrawingManager = false,
    zoom = 14,
    width = 400,
    height = 300,
    customCenter = null,
}) {
    const polygonRef = useRef();

    // initial coords in ocp tech office
    const [currentPosition, setCurrentPosition] = useState({
        lat: -34.5967982,
        lng: -58.3719142,
    });

    // made this to avoid performance issue (google warning and advice)
    const [libraries] = useState(["places", "drawing"]);

    function success(pos) {
        const { latitude, longitude } = pos.coords;

        setCurrentPosition({ lat: latitude, lng: longitude });
    }

    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const center = {
        lat: lat ? parseFloat(lat) : parseFloat(currentPosition.lat),
        lng: lng ? parseFloat(lng) : parseFloat(currentPosition.lng),
    };

    const containerStyle = {
        width: width,
        height: height,
        ...style,
    };

    const options = {
        enableHighAccuracy: true,
    };

    const drawingOptions = {
        // drawingControlOptions: { drawingModes: ['POLYGON'] },
        polygonOptions: {
            editable: isPolygonEditable,
            fillColor: "red",
            strokeColor: "red",
            visible: true,
        },
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error, options);
    }, []);

    const onPolygonUpdate = () => {
        // update polygon edges using polygonRef values on mouse up event
        const paths = polygonRef?.current?.state?.polygon?.latLngs;

        const edgesArray = paths.Uc[0].Uc;

        let polygonEdges = [];

        edgesArray.map(({ lat, lng }) => {
            lat = lat();
            lng = lng();

            polygonEdges.push([lat, lng].join(";;"));
        });

        setResponsePolygons(polygonEdges.join("||"));
    };

    const onPolygonComplete = ({ latLngs }) => {
        // get edges coordinates and concat them for SP sending
        const edgesArray = latLngs.Uc[0].Uc;

        let polygonEdges = [];

        edgesArray.map(({ lat, lng }) => {
            lat = lat();
            lng = lng();

            polygonEdges.push([lat, lng].join(";;"));
        });

        setResponsePolygons(polygonEdges.join("||"));
    };

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries: libraries,
    });

    return (
        isLoaded && (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={customCenter ? customCenter : center}
                zoom={zoom}
            >
                <Marker position={center ? center : ""} />
                {enableDrawingManager && (
                    <DrawingManager
                        drawingMode="polygon"
                        options={drawingOptions}
                        onPolygonComplete={onPolygonComplete}
                    />
                )}
                {polygonPaths.length && (
                    <Polygon
                        paths={polygonPaths}
                        {...drawingOptions.polygonOptions}
                        ref={polygonRef}
                        onMouseUp={onPolygonUpdate}
                    />
                )}
            </GoogleMap>
        )
    );
}
