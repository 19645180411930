import { React } from "react";
import { Link, useLocation } from "react-router-dom";

import { NavigateNext } from "@material-ui/icons";
import { Breadcrumbs, Typography } from "@mui/material";

import { ROUTES } from "consts/routes";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { breadcrumbState, routeInfo } from "recoil/GlobalState";

// import styles from "styles/components/UI/Breadcrumb.module.scss";

export default function Breadcrumb({ title = "Home" }) {
    const breadcrumbs = useRecoilValue(breadcrumbState);
    const location = useLocation();
    const setRoute = useSetRecoilState(routeInfo);

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNext fontSize="small" />}
            sx={{
                "& .MuiSvgIcon-root": {
                    color: "white",
                },
                "& .MuiBreadcrumbs-li": {
                    padding: "0.2em",
                },
            }}
        >
            <Link to={ROUTES["home"].route} replace>
                <Typography
                    button="true"
                    onClick={() => setRoute({ route: ROUTES["home"] })}
                    style={{
                        textDecoration: "none",
                        color: "#FFF",
                        cursor: "pointer",
                    }}
                >
                    {title}
                </Typography>
            </Link>

            {breadcrumbs.length > 1 ? (
                breadcrumbs.map((c, i) =>
                    i < 1 ? (
                        <Link key={i} to={`${c.route}`} replace>
                            <Typography
                                button="true"
                                key={i}
                                onClick={() =>
                                    setRoute({ route: ROUTES[c.route] })
                                }
                                style={{
                                    textDecoration: "none",
                                    color: "#FFF",
                                    cursor: "pointer",
                                }}
                            >
                                {c.name}
                            </Typography>
                        </Link>
                    ) : (
                        <Typography
                            button="true"
                            key={i}
                            onClick={() => setRoute({ route: ROUTES[c.route] })}
                            style={{
                                textDecoration: "none",
                                color: "#FFF",
                            }}
                        >
                            {c.name}
                        </Typography>
                    )
                )
            ) : (
                <Typography
                    button="true"
                    onClick={() =>
                        setRoute({
                            route: ROUTES[location.pathname.split("/")[1]],
                        })
                    }
                    style={{
                        textDecoration: "none",
                        color: "#FFF",
                    }}
                >
                    {ROUTES[location.pathname.split("/")[1]]?.wordKey}
                </Typography>
            )}
        </Breadcrumbs>
    );
}
