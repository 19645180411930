import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/components/UI/ModalForm.module.scss";
import moment from "moment";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [monedas, setMonedas] = useState([]);
    const [monedasIndexes, setMonedasIndexes] = useState([]);

    const [searchParams] = useSearchParams();
    const monedaId = searchParams.get("feriado_id");

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const monedasGet = async (monedaId = null, paisId = null) => {
        const body = {
            service: "MonedaGet",
            params: {
                monedaId: monedaId,
                paisId: paisId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        setMonedas(result);

        // set services indexes for formik validation
        result.map((moneda) => {
            setMonedasIndexes((monedasIndexes) => [
                ...monedasIndexes,
                moneda.monedaId,
            ]);
        });
    };

    const handleSubmit = async ({
        monedaId,
        monedaSerieFecha,
        monedaSerieQMoneda,
        monedaSerieValorComprador,
        monedaSerieValorVendedor,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "MonedaSerieIns",
            params: {
                monedaId: Number(monedaId),
                monedaSerieFecha: moment(monedaSerieFecha).format("YYYY-MM-DD"),
                monedaSerieQMoneda: monedaSerieQMoneda,
                monedaSerieValorComprador: monedaSerieValorComprador,
                monedaSerieValorVendedor: monedaSerieValorVendedor,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        monedasGet();
    }, []);

    return (
        <Formik
            initialValues={{
                monedaId: monedaId,
                monedaSerieFecha: "",
                monedaSerieQMoneda: "",
                monedaSerieValorComprador: "",
                monedaSerieValorVendedor: "",
            }}
            validationSchema={Yup.object({
                monedaId: Yup.number(FORM_ERROR_MESSAGES.number)
                    .oneOf(monedasIndexes, "Moneda seleccionada inválida")
                    .required(FORM_ERROR_MESSAGES.required),
                monedaSerieFecha: Yup.string(FORM_ERROR_MESSAGES.text).required(
                    FORM_ERROR_MESSAGES.required
                ),
                monedaSerieQMoneda: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                monedaSerieValorComprador: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
                monedaSerieValorVendedor: Yup.number(
                    FORM_ERROR_MESSAGES.number
                ).required(FORM_ERROR_MESSAGES.required),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                <div className={styles.column}>
                    <FormikSelectInput
                        disabled={!monedas.length}
                        fullWidth={true}
                        name="monedaId"
                        labelText={"Monedas"}
                    >
                        <option value="">Seleccione una opción...</option>
                        {monedas?.map(({ monedaId, monedaNombre }) => (
                            <option key={monedaId} value={monedaId}>
                                {monedaNombre}
                            </option>
                        ))}
                    </FormikSelectInput>

                    <FormikTextInput
                        fullWidth={true}
                        name="monedaSerieFecha"
                        labelText={"Fecha"}
                        type="date"
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="monedaSerieQMoneda"
                        labelText={"Q Moneda"}
                        type="number"
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="monedaSerieValorComprador"
                        labelText={"Valor Comprador"}
                        type="number"
                    />

                    <FormikTextInput
                        fullWidth={true}
                        name="monedaSerieValorVendedor"
                        labelText={"Valor Vendedor"}
                        type="number"
                    />

                    <FormButton
                        inRequest={inRequest}
                        newFormData={{ button: "Enviar" }}
                        hasConfirmationModal={true}
                    />

                    {isLoading && <CircularIndeterminate />}
                </div>
            </Form>
        </Formik>
    );
}
