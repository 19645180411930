import React, { useState } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

import SearchBox from "./SearchBox";

import { FormButton } from "components";

import styles from "styles/pages/usuariosBO/LocationModal.module.scss";

const containerStyle = {
    width: "100%",
    height: "300px",
    marginBottom: "1rem",
};
const libraries = ["places"];

export default function MapaModal({
    lat,
    lng,
    userInfo,
    setUserInfo,
    setSecondaryModalDetails,
}) {
    const [coordenadas, setCoordenadas] = useState({
        lat: lat
            ? typeof lat === "number"
                ? lat
                : parseFloat(lat)
            : -34.6036844,
        lng: lng
            ? typeof lng === "number"
                ? lng
                : parseFloat(lng)
            : -58.3815591,
    });

    const handleSubmit = () => {
        setUserInfo({
            ...userInfo,
            domicilioLatitud: coordenadas.lat,
            domicilioLongitud: coordenadas.lng,
        });

        setSecondaryModalDetails({ isOpen: false });
    };

    const data = {
        title: "Seleccionar coordenadas",
        button: "Seleccionar coordenadas",
    };

    return (
        <div className={styles.mapaModal}>
            <LoadScript
                id="second-google-map-script"
                googleMapsApiKey="AIzaSyA3CvTeFPuHo7ePjm5YOva66gR-pFWZvPc"
                libraries={libraries}
            >
                <SearchBox
                    coordenadas={coordenadas}
                    setCoordenadas={setCoordenadas}
                />

                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={coordenadas}
                    zoom={14}
                    onClick={(e) =>
                        setCoordenadas({
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                        })
                    }
                >
                    <Marker position={coordenadas} />
                </GoogleMap>

                <FormButton
                    setSecondaryModalDetails={setSecondaryModalDetails}
                    handleSubmitAction={handleSubmit}
                    newFormData={data}
                />
            </LoadScript>
        </div>
    );
}
