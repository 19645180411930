import { React } from "react";
import { XGrid, LicenseInfo } from "@material-ui/x-grid";

import { generateColumns, getGridProps } from "utils/Local-services";

import { useRecoilState, useRecoilValue } from "recoil";
import { routeInfo, rowSelected } from "recoil/GlobalState";

import { LICENSE_KEY } from "consts/env";

import { XGridStyles } from "styles/components/UI/Xgrid.styles";
import { useRef } from "react";

LicenseInfo.setLicenseKey(LICENSE_KEY);

export default function ServerPaginationGrid({
    titles,
    rows,
    rowCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    isLoading,
    setNumberOfPages,
    setSecondarySelected,
    secondarySelected
}) {
    const [selected, setSelected] = useRecoilState(rowSelected);

    const refTable = useRef();

    const { row } = secondarySelected ? secondarySelected : selected;
    const { route } = useRecoilValue(routeInfo);
    const columns = generateColumns(titles, route);
    const setter = setSecondarySelected ? setSecondarySelected : setSelected;
    const styles = XGridStyles();

    const gridProps = getGridProps(columns, rows, row, setter, styles, isLoading);

    return (
        <div className={styles.xgrid}>
            <XGrid
                {...gridProps}
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                count={rowCount}
                loading={isLoading}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={(newPage) => {
                    setPage(newPage);
                }}
                onStateChange={(data) => {
                    const division = Math.ceil(data.state.viewportSizes.height / data.state.density.rowHeight) - 1;
                    if (division !== pageSize) {
                        setPageSize(division);
                    }
                    setNumberOfPages(data.state.pagination.pageCount + 1);
                }}
                ref={refTable}
                autoPageSize
                hideFooterSelectedRowCount
                disableMultipleSelection
                rowHeight={36}
                disableColumnMenu
            />
        </div>
    );
}
