import { makeStyles } from "@material-ui/core/styles";

export const SubmitButtonStyles = makeStyles(() => ({
    root: {
        alignItems: "center",
        width: "max-content!important",
        letterSpacing: "0rem!important",
        height: "2rem!important",
        color: "#ffffff!important",
        opacity: 1,
        background:
            "transparent linear-gradient(90deg, #0C2136 0%, #244C72 100%) 0% 0% no-repeat padding-box;",
        borderRadius: "21px!important",
        padding: "0 2em!important",
        fontWeight: 300,
        fontFamily: '"Roboto", sans-serif',
        textTransform: "capitalize!important",
        "&:hover": {
            background:
                "transparent linear-gradient(90deg, #1C3136 0%, #345C72 100%) 0% 0% no-repeat padding-box;",
        },
        "&:disabled": {
            background: "#A7A7A7",
        },
    },
}));
