import { makeStyles } from "@material-ui/core/styles";

export const FormButtonsStyle = makeStyles({
    container: {
        display: "flex!important",
        flexDirection: "row-reverse!important",
        gap: "2em",
        justifyContent: "center!important",
        margin: "1.5em 0 1.5em 0",
        width: "100%!important",
    },
    defaultBtn: {
        background:
            "transparent linear-gradient(90deg, #0C2136 0%, #244C72 100%) 0% 0% no-repeat padding-box;",
        borderRadius: "21px!important",
        color: "#ffffff!important",
        fontFamily: '"Roboto", sans-serif',
        fontWeight: 300,
        letterSpacing: "0rem",
        marginLeft: "1rem",
        opacity: 1,
        textTransform: "capitalize!important",
        width: "19rem",
        "&:hover": {
            background:
                "transparent linear-gradient(90deg, #1C3136 0%, #345C72 100%) 0% 0% no-repeat padding-box;",
        },
        "&:disabled": {
            background: "#A7A7A7!important",
        },
    },
    redBtn: {
        width: "19rem",
        background: "#D44141!important",
        borderRadius: "21px!important",
        marginLeft: "1rem",
        opacity: 1,
        textTransform: "capitalize!important",
        color: "#ffffff!important",
        fontWeight: 300,
        fontFamily: '"Roboto", sans-serif!important',
        "&:hover": {
            background: "#D44141!important",
        },
        "&:disabled": {
            background: "#A7A7A7!important",
        },
    },
    whiteBtn: {
        color: "inherit!important",
        width: "19rem!important",
        background: "#ddd!important",
        borderRadius: "21px!important",
        opacity: 1,
        textTransform: "capitalize!important",
        fontWeight: 300,
        fontFamily: '"Roboto", sans-serif',
    },
});
