import React, { useEffect, useState } from "react";

import { CircularIndeterminate, FormButton } from "components";
import {
    FormikSelectInput,
    FormikTextInput,
} from "components/UI/Inputs/FormikInputs";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, snackbarData, userCredentials } from "recoil/GlobalState";

import { FORM_ERROR_MESSAGES } from "consts/errorsMessages";
import { ACTIONS } from "consts/actions";

import { RequestServiceActions } from "services/mia-services/RequestServiceActions";

import styles from "styles/components/UI/ModalForm.module.scss";
import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

export default function InsertModal({ handleUpdateTable }) {
    const [inRequest, setInRequest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [types, setTypes] = useState([]);
    const [typesIndexes, setTypesIndexes] = useState([]);

    const credentials = useRecoilValue(userCredentials);

    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setModalDetails = useSetRecoilState(modalData);

    const getRegistroTypes = async (
        tipoRegistroId = null,
        tipoRegistroCod = null
    ) => {
        const body = {
            service: "TipoDeRegistroGet",
            params: {
                tipoRegistroId: tipoRegistroId,
                tipoRegistroCod: tipoRegistroCod,
            },
        };

        const typesResponse = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setTypes(typesResponse);

        // set provinces indexes for formik validation
        typesResponse.map((type) => {
            setTypesIndexes((typesIndexes) => [
                ...typesIndexes,
                type.tipoRegistroId,
            ]);
        });
    };

    const handleSubmit = async ({
        registroCod,
        registroNombre,
        tipoRegistroId,
        registroDescripcion,
        registroRequiereBO,
    }) => {
        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "RegistroIns",
            params: {
                registroCod: String(registroCod).toUpperCase(),
                registroNombre: registroNombre,
                tipoRegistroId: Number(tipoRegistroId),
                registroDescripcion: registroDescripcion,
                registroRequiereBO: registroRequiereBO,
                userId: credentials.userId,
            },
        };

        await RequestServiceActions(
            body,
            credentials,
            ACTIONS.INSERT,
            setSnackbarInfo
        );
        await handleUpdateTable();

        setModalDetails((modalState) => !modalState);

        setInRequest((inRequest) => !inRequest);
        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        getRegistroTypes();
    }, []);

    return (
        <Formik
            initialValues={{
                registroCod: "",
                registroNombre: "",
                tipoRegistroId: "",
                registroDescripcion: "",
                registroRequiereBO: "",
            }}
            validationSchema={Yup.object({
                registroCod: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(6, "Máximo 6 caracteres"),
                registroNombre: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(45, "Máximo 45 caracteres"),
                tipoRegistroId: Yup.number(FORM_ERROR_MESSAGES.number)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(typesIndexes, "Tipo de registro inválido"),
                registroDescripcion: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .max(500, "Máximo 500 caracteres"),
                registroRequiereBO: Yup.string(FORM_ERROR_MESSAGES.text)
                    .required(FORM_ERROR_MESSAGES.required)
                    .oneOf(["Y", "N"]),
            })}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    handleSubmit(values);
                } catch (e) {
                    setSnackbarInfo({
                        message: e.errmsg,
                        severity: "error",
                        open: true,
                    });
                    setTimeout(() => {
                        setSnackbarInfo((data) => !data.open);
                    }, 3000);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            <Form className={styles.inputs} noValidate>
                {!types.length ? (
                    <CircularIndeterminate />
                ) : (
                    <div className={styles.column}>
                        <FormikTextInput
                            fullWidth={true}
                            name="registroCod"
                            labelText={"Código"}
                        />

                        <FormikTextInput
                            fullWidth={true}
                            name="registroNombre"
                            labelText={"Nombre"}
                        />

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="tipoRegistroId"
                            labelText={"Tipo"}
                        >
                            <option value="">Seleccione una opción...</option>
                            {types?.map(
                                ({ tipoRegistroId, tipoRegistroNombre }) => (
                                    <option
                                        key={tipoRegistroId}
                                        value={tipoRegistroId || ""}
                                    >
                                        {tipoRegistroNombre}
                                    </option>
                                )
                            )}
                        </FormikSelectInput>

                        <FormikTextInput
                            fullWidth={true}
                            name="registroDescripcion"
                            labelText={"Descripción"}
                        />

                        <FormikSelectInput
                            defaultValue={""}
                            fullWidth={true}
                            name="registroRequiereBO"
                            labelText={"Requiere BO"}
                        >
                            <option value="">Seleccione una opción...</option>
                            <option value="Y">Sí</option>
                            <option value="N">No</option>
                        </FormikSelectInput>

                        <FormButton
                            inRequest={inRequest}
                            newFormData={{ button: "Enviar" }}
                            hasConfirmationModal={true}
                        />

                        {isLoading && <CircularIndeterminate />}
                    </div>
                )}
            </Form>
        </Formik>
    );
}
