import { makeStyles } from "@material-ui/core/styles";

export const ModalStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:focus": {
            outline: "none",
        },
        paddingTop: theme.spacing(4),
        width: "100%",
        height: "100vh",
        marginBottom: "5%",
        [theme.breakpoints.up("xl")]: {
            paddingTop: theme.spacing(2),
            marginBottom: "1%",
        },
    },
    paper: {
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "1rem",
        boxShadow: theme.shadows[10],
        display: "flex",
        flexDirection: "column",
        height: "auto",
        justifyContent: "flex-start",
        outline: "none",
        padding: "2em 2em 1.5em 2em",
        position: "relative",
        textAlign: "center",
        width: "80%",
    },
    title: {
        display: "flex",
        justifyContent: "center",
        color: "#0C2136",
        fontWeight: "300",
        fontSize: "2rem",
        marginBottom: "1.3rem",
    },
    closeButton: {
        cursor: "pointer",
        fontSize: "1em",
        position: "absolute",
        right: "2em",
        top: "1em",
    },
}));
