import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CircularIndeterminate } from "components";

import { PersonalDataDashboard } from "./PersonalDataDashboard";

import { sections } from "consts/section";
import { ROUTES } from "consts/routes";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, snackbarData, userCredentials } from "recoil/GlobalState";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/usuarios/UserDetailsDashboard.module.scss";
import EnrollDataDashboard from "./EnrollDataDashboard";

export default function UserDetailsDashboard() {
    const [user, setUser] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [section, setSection] = useState(sections.PERSONAL_DATA);

    const credentials = useRecoilValue(userCredentials);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [searchParams] = useSearchParams();

    const getUser = async (userId) => {
        setIsLoading(true);

        const body = {
            service: "MIADemoUsuarioFichaGet",
            params: {
                usuarioId: userId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );
        setUser(result[0]);

        setIsLoading(false);
    };

    useEffect(() => {
        const userId = searchParams.get("user_id");

        getUser(userId);
        setRoute(ROUTES.usuarios);
    }, []);

    return (
        <div className={styles.div}>
            {isLoading || !user ? (
                <CircularIndeterminate />
            ) : (
                <section className={styles.dashboard}>
                    <button
                        className={styles.switchButton}
                        onClick={() => setSection(sections.PERSONAL_DATA)}
                    >
                        Datos Personales
                    </button>
                    <button
                        className={styles.switchButton}
                        onClick={() => setSection(sections.ENROLL_DATA)}
                    >
                        Datos de Enrolamiento
                    </button>

                    {section === sections.PERSONAL_DATA && (
                        <PersonalDataDashboard user={user} />
                    )}
                    {section === sections.ENROLL_DATA && (
                        <EnrollDataDashboard userId={user.usuarioId} />
                    )}
                </section>
            )}
        </div>
    );
}
