import React, { useEffect, useState } from "react";

import { actividadAfip as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function ActividadesAfip() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [actividadAfipLocalState, setActividadAfipLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatActividadAfip();
        setRow({ status: false, row: {} });
    };

    const formatActividadAfip = async (
        actividadAfipId = null,
        actividadAfipCod = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "ActividadAFIPGet",
            params: {
                actividadAfipId: actividadAfipId,
                actividadAfipCod: actividadAfipCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const actividadAfipPartial = result.reduce((acc, item) => {
            const {
                actividadAFIPId,
                actividadAFIPCod,
                actividadAFIPDescripcion,
                actividadAFIPDetalle,
                actividadAFIPVersion,
            } = item;

            const actividadAfip = {
                id: actividadAFIPId,
                actividadAfipCod: actividadAFIPCod,
                actividadAfipDescripcion: actividadAFIPDescripcion,
                actividadAfipDetalle: actividadAFIPDetalle,
                actividadAfipVersion: actividadAFIPVersion,
                [titles[0]]: String(actividadAFIPCod),
                [titles[1]]: actividadAFIPDescripcion,
                [titles[2]]: actividadAFIPDetalle,
            };

            return [...acc, actividadAfip];
        }, []);

        setActividadAfipLocalState(actividadAfipPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (Object.values(credentials).length === 0) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Actividad Afip - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Actividad Afip - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Actividad Afip - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={actividadAfipLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
