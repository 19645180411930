import React, { useEffect, useState } from "react";

import { devices as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import lupa from "assets/icons/con-activar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
    breadcrumbState,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";
import DeleteModal from "./Actions/DeleteModal";
import InsertModal from "./Actions/InsertModal";
import { Link } from "react-router-dom";
import { EditIcon } from "assets/icons";

export default function Devices() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setBreadCrumbs = useSetRecoilState(breadcrumbState);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [deviceLocalState, setDeviceLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatDevice();
        setRow({ status: false, row: {} });
    };

    const formatDevice = async (
        deviceId = null,
        deviceTipoId = null,
        clienteId = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "DeviceGet",
            params: {
                deviceId: deviceId,
                deviceTipoId: deviceTipoId,
                clienteId: clienteId,
            },
        };
        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const devicePartial = result.reduce((acc, item) => {
            const {
                deviceId, deviceIdentificacion, deviceMAC, deviceGCMToken, tipoDeviceId, tipoDeviceNombre,
                merchantId, merchantNombre, servicioId, servicioNombre, aplicacionId,
                aplicacionNombre, deviceVersion, deviceMarca, deviceModelo, deviceSerie, deviceFechaCompra, deviceStatus,
                gateDeviceRequiereEntrada, gateDeviceRequiereSalida, gateDeviceLicencia, gateDeviceUmbral, gateDeviceDelta, gateDeviceTiempoAccion
            } = item;

            const deviceType = {
                id: deviceId,
                tipoDeviceId,
                deviceIdentificacion,
                clienteId: merchantId,
                servicioId,
                aplicacionId,
                deviceVersion, deviceMarca, deviceModelo, deviceSerie, deviceFechaCompra, deviceStatus,
                gateDeviceRequiereEntrada, gateDeviceRequiereSalida, gateDeviceLicencia, gateDeviceUmbral, gateDeviceDelta, gateDeviceTiempoAccion,
                [titles[0]]: deviceIdentificacion,
                [titles[1]]: tipoDeviceNombre,
                [titles[2]]: deviceMarca,
                [titles[3]]: deviceModelo,
                [titles[4]]: deviceSerie,
                [titles[5]]: deviceMAC,
                [titles[6]]: deviceGCMToken,
                [titles[7]]: deviceFechaCompra,
                [titles[8]]: merchantNombre,
                [titles[9]]: servicioNombre,
                [titles[10]]: aplicacionNombre,
            };

            return [...acc, deviceType];
        }, []);

        setDeviceLocalState(devicePartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (Object.keys(credentials).length === 0) return;

        setBreadCrumbs([
            { name: ROUTES.devices.wordKey, route: ROUTES.devices.route },
        ]);

        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={false}
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Device - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={EditIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Device - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Device - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={deviceLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
