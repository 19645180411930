import React, { useEffect, useState } from "react";

import { registro as titles } from "consts/tableTitles";

import reload from "assets/icons/actualizar.svg";
import addIcon from "assets/icons/rediseño.svg";
import borrar from "assets/icons/eliminar.svg";
import editIcon from "assets/icons/editar.svg";

import XGridDemo from "components/UI/Grid/Xgrid";
import { Buttons, HeaderButton, TransitionModal } from "components";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoil/GlobalState";

import { ROUTES } from "consts/routes";
import { ACTIONS } from "consts/actions";

import { InsertModal, DeleteModal, UpdateModal } from "./Actions";

import { RequestServiceGet } from "services/mia-services/RequestServiceGet";

import styles from "styles/pages/Tables.module.scss";

export default function Registro() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [registroLocalState, setRegistroLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatRegistro();
        setRow({ status: false, row: {} });
    };

    const formatRegistro = async (
        registroId = null,
        registroCod = null,
        registroRequiereBO = null,
        tipoRegistroId = null,
        tipoRegistroCod = null
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "RegistroGet",
            params: {
                registroId: registroId,
                registroCod: registroCod,
                registroRequiereBO: registroRequiereBO,
                tipoRegistroId: tipoRegistroId,
                tipoRegistroCod: tipoRegistroCod,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const registroPartial = result.reduce((acc, item) => {
            const {
                tipoRegistroId,
                tipoRegistroCod,
                tipoRegistroNombre,
                registroCod,
                registroNombre,
                registroDescripcion,
                registroRequiereBO,
                registroId,
                registroUID,
                registroUFH,
                registroVersion,
            } = item;

            const registro = {
                id: registroId,
                tipoRegistroId: tipoRegistroId,
                tipoRegistroCod: tipoRegistroCod,
                tipoRegistroNombre: tipoRegistroNombre,
                registroDescripcion: registroDescripcion,
                registroRequiereBO: registroRequiereBO,
                registroCod: registroCod,
                registroNombre: registroNombre,
                registroUID: registroUID,
                registroUFH: registroUFH,
                registroVersion: registroVersion,
                [titles[0]]: tipoRegistroCod ? tipoRegistroCod : "N/A",
                [titles[1]]: tipoRegistroNombre ? tipoRegistroNombre : "N/A",
                [titles[2]]: registroNombre,
                [titles[3]]: registroDescripcion,
                [titles[4]]: registroRequiereBO === "N" ? "No" : "Sí",
            };

            return [...acc, registro];
        }, []);

        setRegistroLocalState(registroPartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (!credentials) return;
        handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(ROUTES[window.location.pathname.split("/")[1]]);
    }, []);

    return (
        <section className={styles.section}>
            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>

            <Buttons>
                <>
                    {row.status ? (
                        <>
                            <HeaderButton
                                text={"Eliminar"}
                                x
                                icon={borrar}
                                data={{
                                    action: ACTIONS.DELETE,
                                    title: "Registro - Eliminar",
                                    button: "Confirmar",
                                }}
                            />
                            <HeaderButton
                                text={"Editar"}
                                x
                                icon={editIcon}
                                data={{
                                    action: ACTIONS.UPDATE,
                                    title: "Registro - Editar",
                                    button: "Confirmar",
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <HeaderButton
                                text={"Crear"}
                                icon={addIcon}
                                data={{
                                    action: ACTIONS.INSERT,
                                    title: "Registro - Crear",
                                    button: "Enviar",
                                }}
                            />

                            <HeaderButton
                                text={"Actualizar página"}
                                icon={reload}
                                updateList={handleUpdateTable}
                            />
                        </>
                    )}
                </>
            </Buttons>

            <XGridDemo
                rows={registroLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
